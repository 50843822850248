<template>
  <div>
    <v-card flat class="ma-0 pa-0" color="modal">
      <v-toolbar flat color="transparent">
        <v-toolbar-title> Create Booking </v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- <v-btn v-if="!validDetails" text @click="saveBooking()" :disabled="!booking.customerId" :loading="savingBooking" color="blue"><v-icon
                        left>save</v-icon> Save as Draft</v-btn>
        <v-btn icon @click="saveBooking()" :disabled="!booking.customerId ||
          !booking.etd ||
          (booking.movementType == 'OCEAN' && !booking.vesselId) ||
          (booking.bookingContainerItems.length > 0 &&
            booking.bookingContainerItems.some(container =>
              !container.transportCoordinator || !container.transportCoordinator.name))
          " :loading="savingBooking" color="secondary"><v-icon>save</v-icon></v-btn> -->
        <template>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <!-- <v-btn v-if="!validDetails" text @click="saveBooking()" :disabled="!booking.customerId" :loading="savingBooking" color="blue"><v-icon
                        left>save</v-icon> Save as Draft</v-btn> -->
                <v-btn icon @click="saveBooking()" :disabled="
                (booking.movementType === 'LOCAL' && (!booking.etd || !booking.eta || !booking.customerId)) ||
                (booking.movementType !== 'LOCAL' && 
                (!booking.customerId ||
                  !booking.etd ||
                  !booking.contractOwner ||
                  (booking.movementType == 'OCEAN' && !booking.vesselId) ||
                  (booking.bookingContainerItems.length > 0 &&
                    booking.bookingContainerItems.some(container =>
                      (!container.isCarrierHaulage && !container.isRailage) &&
                      (!container.transportCoordinator || !container.transportCoordinator.name)

                    ))))
                  " :loading="savingBooking" color="secondary"><v-icon>save</v-icon></v-btn>
              </div>
            </template>
            <span>
              <div v-if="booking.movementType != 'LOCAL' && !booking.customerId || !booking.etd || !booking.vesselId || !booking.contractOwner || (booking.bookingContainerItems.length > 0 &&
                booking.bookingContainerItems.some(container =>
                  (!container.isCarrierHaulage && !container.isRailage) &&
                  (!container.transportCoordinator || !container.transportCoordinator.name)))">
                <span style="font-size: 12px" class="text-decoration-underline"> Reasons why button is disabled: </span>
              </div>

              <div v-if="!booking.customerId">
                <span style="font-size: 12px; color:#ff6666">No Customer Added</span>
              </div>
              <div v-if="!booking.etd">
                <span style="font-size: 12px; color:#ff6666">No ETD Added</span>
              </div>
              <div v-if="booking.movementType === 'LOCAL' && !booking.eta">
                <span style="font-size: 12px; color:#ff6666">No ETA Added</span>
              </div>
              <div v-if=" booking.movementType != 'LOCAL' && !booking.vesselId">
                <span style="font-size: 12px;color:#ff6666">No Vessel Added</span>
              </div>
              <div v-if="booking.movementType != 'LOCAL' && !booking.contractOwner">
                <span style="font-size: 12px; color:#ff6666">No Contract Owner Selected</span>
              </div>
              <div v-if="booking.movementType != 'LOCAL' && booking.bookingContainerItems && booking.bookingContainerItems.length > 0 &&
                booking.bookingContainerItems.some(container =>
                  (!container.isCarrierHaulage && !container.isRailage) &&
                  (!container.transportCoordinator || !container.transportCoordinator.name))">
                <span style="font-size: 12px;color:#ff6666">Container(s) missing transporter coordinator</span>
              </div>
            </span>
          </v-tooltip>
        </template>
        <v-btn text @click="$emit('close'), booking = {}">X</v-btn>
      </v-toolbar>
      <v-card-text class="mt-0 pt-0">
        <v-row>
          <v-col cols="12" sm="4">
            <v-card style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              " outlined>
              <v-card-text>
                <v-list dense class="text-center">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">settings</v-icon>Settings
                  </v-subheader>
                 <v-list dense class="text-left">
                    <!--  <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>import_export</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Movement Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small @click="changeMovementType()" :color="booking.movementType == 'EXPORT'
                          ? 'deep-orange'
                          : 'blue darken-2'
                          ">
                          <v-icon small left>import_export</v-icon>
                          <span style="text-transform: none; color: white">
                            {{ $Format.capitalizeFirstLetter(booking.movementType) }}
                          </span>
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item> -->
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>import_export</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Movement Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small :color="booking.movementType == 'EXPORT'
                          ? 'deep-orange'
                          : booking.movementType == 'IMPORT'?
                          'blue darken-2':'green darken-2'
                          ">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ $Format.capitalizeFirstLetter(booking.movementType) }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="booking.movementType = 'EXPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Export</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.movementType = 'IMPORT'">
                              <v-list-item-content>
                                <v-list-item-title>Import</v-list-item-title>
                        
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.movementType = 'LOCAL', booking.modeOfTransport = 'ROAD'">
                              <v-list-item-content>
                                <v-list-item-title>Local</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>airline_stops</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Cargo Service Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="indigo">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ booking.serviceType }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="booking.serviceType = 'FCL'">
                              <v-list-item-content>
                                <v-list-item-title>FCL</v-list-item-title>
                                <v-list-item-subtitle>Full Container Load</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.serviceType = 'LCL'">
                              <v-list-item-content>
                                <v-list-item-title>LCL</v-list-item-title>
                                <v-list-item-subtitle>Less than Container
                                  Load</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.serviceType = 'BREAKBULK'">
                              <v-list-item-content>
                                <v-list-item-title>Breakbulk</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Export Documentation</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch></v-switch>
                      </v-list-item-action>
                      </v-list-item>
                      <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Import Documentation</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch></v-switch>
                      </v-list-item-action>
                      </v-list-item>
                      <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Transport</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch></v-switch>
                      </v-list-item-action>
                      </v-list-item>
                      <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Monitoring</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch></v-switch>
                      </v-list-item-action>
                      </v-list-item> -->
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Booking Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="teal">
                              <v-icon small left v-if="booking.type == 'LOGISTICS'">mode_of_travel</v-icon>
                              <v-icon small left v-else-if="booking.type == 'DOCUMENTATION'">article</v-icon>
                              <v-icon small left v-else-if="booking.type == 'HAULAGE'">local_shipping</v-icon>
                              <v-icon small left v-else-if="booking.type == 'MONITORING'">thermostat</v-icon>

                              <span style="text-transform: none; color: white">
                                {{ $Format.capitalizeFirstLetter(booking.type) }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="booking.type = 'LOGISTICS'">
                              <v-list-item-content>
                                <v-list-item-title>Logistics</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.type = 'DOCUMENTATION'">
                              <v-list-item-content>
                                <v-list-item-title>Documentation Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="booking.type = 'HAULAGE'">
                              <v-list-item-content>
                                <v-list-item-title>Transport Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.type = 'MONITORING'">
                              <v-list-item-content>
                                <v-list-item-title>Monitoring Only</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>mode_of_travel</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Mode of Transport</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small color="blue-grey darken-2">
                              <v-icon small left v-if="booking.modeOfTransport == 'OCEAN'">directions_boat</v-icon>
                              <v-icon small left v-else-if="booking.modeOfTransport == 'AIR'">flight</v-icon>
                              <v-icon small left v-else-if="booking.modeOfTransport == 'RAIL'">train</v-icon>
                              <v-icon small left v-else-if="booking.modeOfTransport == 'ROAD'">local_shipping</v-icon>

                              <span style="text-transform: none; color: white">
                                {{
                                  $Format.capitalizeFirstLetter(booking.modeOfTransport)
                                }}
                              </span>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item @click="booking.modeOfTransport = 'OCEAN'">
                              <v-list-item-content>
                                <v-list-item-title>Ocean</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.modeOfTransport = 'AIR'">
                              <v-list-item-content>
                                <v-list-item-title>Air</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.modeOfTransport = 'ROAD'">
                              <v-list-item-content>
                                <v-list-item-title>Road</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="booking.modeOfTransport = 'RAIL'">
                              <v-list-item-content>
                                <v-list-item-title>Rail</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <!-- <v-divider></v-divider> -->
                  <!-- <div class="text-left"> -->
                  <!-- <v-btn-toggle
                  class="my-1 mx-1"
                  mandatory
                  outlined
                  v-model="importExport"
                  rounded
                  color="teal"
                >
                  <v-btn
                    small
                    outlined
                    @click="booking.movementType = 'EXPORT'"
                  >
                    <v-icon small left>import_export</v-icon>
                    <span style="text-transform: none; color: white">
                      Export
                    </span>
                  </v-btn>
                  <v-btn small outlined @click="setImportBooking()">
                    <v-icon small left>import_export</v-icon>
                    <span style="text-transform: none; color: white">
                      Import
                    </span>
                  </v-btn>
                </v-btn-toggle> -->
                  <!-- <br />
                    <v-btn-toggle
                      class="my-1 mx-1"
                      mandatory
                      outlined
                      v-model="serviceType"
                      rounded
                      color="indigo"
                    >
                      <v-btn
                        small
                        outlined
                        @click="booking.serviceType = 'FCL'"
                      >
                        <span style="text-transform: none; color: white">
                          FCL
                        </span>
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        @click="booking.serviceType = 'LCL'"
                      >
                        <span style="text-transform: none; color: white">
                          LCL
                        </span>
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        @click="booking.serviceType = 'BREAKBULK'"
                      >
                        <span style="text-transform: none; color: white">
                          Breakbulk
                        </span>
                      </v-btn>
                    </v-btn-toggle> -->
                  <!-- <br/> -->
                  <!-- <v-btn-toggle
                      class="my-1"
                      mandatory
                      outlined
                      v-model="bookingType"
                      rounded
                      color="blue"
                    >
                      <v-btn small outlined @click="booking.type = 'FREIGHT'">
                        <v-icon small left>mode_of_travel</v-icon>
                        <span style="text-transform: none; color: white">
                          Freight
                        </span>
                      </v-btn>
                      <v-btn small outlined @click="booking.type = 'HAULAGE'">
                        <v-icon small left>local_shipping</v-icon>
                        <span style="text-transform: none; color: white">
                          Transport Only
                        </span>
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        @click="booking.type = 'MONITORING'"
                      >
                        <v-icon small left>thermostat</v-icon>
                        <span style="text-transform: none; color: white">
                          Monitoring Only
                        </span>
                      </v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle
                      mandatory
                      outlined
                      v-model="bookingMode"
                      rounded
                      color="blue-grey"
                      class="my-1"
                    >
                      <v-btn
                        small
                        outlined
                        @click="booking.modeOfTransport = 'OCEAN'"
                      >
                        <v-icon small left>directions_boat</v-icon>
                        <span style="text-transform: none; color: white">
                          Ocean
                        </span>
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        @click="booking.modeOfTransport = 'AIR'"
                      >
                        <v-icon small left>flight</v-icon>
                        <span style="text-transform: none; color: white">
                          Air
                        </span>
                      </v-btn>
                      <v-btn
                        small
                        outlined
                        @click="booking.modeOfTransport = 'RAIL'"
                      >
                        <v-icon small left>train</v-icon>
                        <span style="text-transform: none; color: white">
                          Rail
                        </span>
                      </v-btn>
                    </v-btn-toggle>
                  </div> -->
                  <!-- <v-expand-transition>
                            <div class="text-center">
                             <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="grey">airline_stops</v-icon> Main Carriage Mode
                            </v-subheader>
                            
                            </div>
                           </v-expand-transition> -->

                  <!-- <v-divider></v-divider> -->

                  <v-divider></v-divider>

                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">groups</v-icon>
                    Parties</v-subheader>
                  <v-divider></v-divider>
                  <v-list class="text-left" dense>
                    <v-list-item v-if="booking.customer">
                      <v-list-item-action>
                        <v-avatar v-if="booking.customer.logo" color="white">
                          <v-img :src="booking.customer.logo" contain></v-img>
                        </v-avatar>
                        <v-avatar v-else color="secondary">
                          <h2>{{ booking.customer.name ? booking.customer.name.charAt(0) : '' }}</h2>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ booking.customer.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Selected Customer
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn small @click="customerSearchModal = true" icon><v-icon small>edit</v-icon></v-btn>
                          <v-btn small @click="booking.customer = undefined" color="red" icon><v-icon
                              small>delete</v-icon></v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-else @click="customerSearchModal = true">
                      <v-list-item-action>
                        <v-icon color="primary">add_circle_outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <span style="font-size: 16px">Add Customer</span>
                        <v-list-item-subtitle>
                          <i>Required</i>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="booking.customer" :key="profileKey">
                      <v-list-item-action>
                        <v-icon color="secondary">handshake</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.consigneeProfile">
                          {{ booking.consigneeProfile.systemReference }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Shipment Profile
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center" v-if="booking.consigneeProfile">
                          <v-btn small @click="shipmentProfileDialog = true" icon><v-icon small>edit</v-icon></v-btn>
                          <v-btn small @click="removeProfile()" color="red" icon><v-icon small>delete</v-icon></v-btn>
                        </v-row>
                        <v-btn icon color="primary" v-else @click="shipmentProfileDialog = true">
                          <v-icon>add_circle_outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- <v-list-item
                     
                      
                    >
                      <v-list-item-action>
                        <v-icon color="primary">add_circle_outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <span style="font-size: 16px"
                          >Add Shipment Profile</span
                        >
                      </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item v-if="booking.consigneeProfile" @click="shipmentProfileConfig = true">
                      <v-list-item-action>
                        <v-icon v-if="booking.dealTerm" color="secondary">settings</v-icon>
                        <v-icon v-else color="primary">add_circle_outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-content v-if="!booking.dealTerm">
                        <span style="font-size: 16px">Select Profile Configuration</span>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-title>
                          {{ booking.dealTerm.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Profile Configuration
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="incoTermModal = true" v-if="!booking.consigneeProfile">
                      <v-list-item-action>
                        <v-icon :color="!booking.incoTerm ? 'secondary' : 'success'">swap_horiz</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.incoTerm">
                          {{ booking.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle> Inco Term </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <div v-if="booking.customer">
                      <!-- <v-list-item :disabled="booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                        " @click="(partyModal = true), (partyType = 'Shipper')">
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey">directions_boat_filled</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.shipper">
                            {{ booking.shipper.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle v-if="booking.onBehalfShipper">
                            On behalf of {{ booking.onBehalfShipper.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1">label</v-icon>
                            Shipper
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->
                      <v-list-item
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      v-if="booking.movementType !== 'IMPORT'"
                      @click="(partyModal = true), (partyType = 'Shipper')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >directions_boat_filled</v-icon
                        >
                   
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.shipper">
                          {{ booking.shipper.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="booking.onBehalfShipper">
                          On behalf of
                          {{ booking.onBehalfShipper.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Shipper
                        </v-list-item-subtitle>

                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item 
                    :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                    v-else>
                    <v-list-item-action>
                      <v-icon >directions_boat_filled</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.shipper">
                        {{ booking.shipper.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle v-if="booking.onBehalfShipper">
                        On behalf of
                          {{ booking.onBehalfShipper.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Shipper
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center" v-if="booking.shipper">
                        <v-btn small      @click="(partyModal = true), (partyType = 'Shipper')" icon
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        
                        <v-btn small @click="booking.shipper = null,removeImportShipperPart()" color="red" icon
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                      <v-btn
                        icon
                        color="primary"
                        v-else
                        @click="(partyModal = true), (partyType = 'Shipper')"
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    </v-list-item>
                      <v-list-item :disabled="booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                        " @click="(partyModal = true), (partyType = 'Forwarder')">
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey">fast_forward</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.forwarder">
                            {{ booking.forwarder.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle v-if="booking.onBehalfForwarder">
                            On behalf of {{ booking.onBehalfForwarder.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1">label</v-icon>
                            Forwarder
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item :disabled="booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                        " @click="(partyModal = true), (partyType = 'Consignee')">
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey">call_received</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.consignee">
                            {{ booking.consignee.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle v-if="booking.onBehalfConsignee">
                            On behalf of {{ booking.onBehalfConsignee.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1">label</v-icon>
                            Consignee
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item :disabled="booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                        " @click="(partyModal = true), (partyType = 'Buyer')">
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey">shopping_cart</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.buyer">
                            {{ booking.buyer.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle v-if="booking.onBehalfBuyer">
                            On behalf of {{ booking.onBehalfBuyer.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1">label</v-icon>
                            Buyer
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="(partyModal = true), (partyType = 'Stock Provider')">
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey">fact_check</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.stockProvider">
                            {{ booking.stockProvider.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1">label</v-icon>
                            Stock Provider
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item
                        :disabled="
                          booking.consigneeProfile != undefined ||
                          booking.consigneeProfile != null
                        "
                        @click="
                          (partyModal = true), (partyType = 'First Notify')
                        "
                      >
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey"
                            >notifications</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.firstNotify">
                            {{ booking.firstNotify.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1"
                              >label</v-icon
                            >
                            Notify Party
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        :disabled="
                          booking.consigneeProfile != undefined ||
                          booking.consigneeProfile != null
                        "
                        @click="
                          (partyModal = true), (partyType = 'Second Notify')
                        "
                      >
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey"
                            >notifications</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.secondNotify">
                            {{ booking.secondNotify.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1"
                              >label</v-icon
                            >
                            2nd Notify Party
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->

                      <!-- <v-list-item
                        :disabled="
                          booking.consigneeProfile != undefined ||
                          booking.consigneeProfile != null
                        "
                        @click="
                          (partyModal = true), (partyType = 'Courier Party')
                        "
                      >
                        <v-list-item-action>
                          <v-icon class="mt-0 pt-0" color="grey"
                            >local_post_office</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.courierParty">
                            {{ booking.courierParty.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-icon x-small color="grey" class="mr-1"
                              >label</v-icon
                            >
                            Courier Party
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->
                    </div>
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              " outlined>
              <v-card-text>
                <v-list dense>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">location_on</v-icon>
                    Origin & Destination</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item @click="(countryType = 'Origin'), (countryModal = true)" :disabled="booking.consigneeProfile != undefined &&
                    booking.consigneeProfile != null
                    ">
                    <v-list-item-action>
                      <v-icon :color="!booking.originCountry ? 'secondary' : 'success'
                        ">public</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.originCountry">
                        {{ booking.originCountry.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Origin Country
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img contain v-if="booking.originCountry"
                          :src="`https://cdn.loglive.io/flags/4x3/${booking.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="polModal = true">
                    <v-list-item-action>
                      <v-icon :color="!booking.portOfLoadValue ? 'secondary' : 'success'
                        ">anchor</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.portOfLoadCity">
                        {{ booking.portOfLoadCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Port of Load
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="booking.portOfLoadValue" pill small outlined>
                        <v-avatar size="24" left>
                          <v-img contain v-if="booking.portOfLoadValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        {{ booking.portOfLoadValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="podModal = true">
                    <v-list-item-action>
                      <v-icon :color="!booking.portOfDischargeValue
                        ? 'secondary'
                        : 'success'
                        ">anchor</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.portOfDischargeCity">
                        {{ booking.portOfDischargeCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Port of Discharge
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="booking.portOfDischargeValue" pill small outlined>
                        <v-avatar size="24" left>
                          <v-img contain v-if="booking.portOfDischargeValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        {{ booking.portOfDischargeValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="fdModal = true">
                    <v-list-item-action>
                      <v-icon :color="!booking.finalDestinationValue
                        ? 'secondary'
                        : 'success'
                        ">location_on</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.finalDestinationCity">
                        {{ booking.finalDestinationCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Final Destination
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="booking.finalDestinationValue" pill small outlined>
                        <v-avatar size="24" left>
                          <v-img contain v-if="booking.finalDestinationValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        {{ booking.finalDestinationValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="
                    (countryType = 'Destination'), (countryModal = true)
                    " :disabled="booking.consigneeProfile != undefined &&
    booking.consigneeProfile != null
    ">
                    <v-list-item-action>
                      <v-icon :color="!booking.destinationCountry ? 'secondary' : 'success'
                        ">public</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.destinationCountry">
                        {{ booking.destinationCountry.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Destination Country
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img contain v-if="booking.destinationCountry"
                          :src="`https://cdn.loglive.io/flags/4x3/${booking.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon :color="'secondary'">not_listed_location</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        On Carriage
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="booking.onCarriage" color="blue"></v-switch>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="grey">mode_of_travel</v-icon>Transport
                    Details</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="booking.modeOfTransport == 'OCEAN'">
                    <v-list-item-action v-if="!booking.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar color="white" size="36" v-if="booking.shippingLine">
                        <v-img v-if="booking.shippingLine && booking.shippingLine.logo
                          " :src="booking.shippingLine.logo" contain></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="booking.vessel">
                      <v-list-item-title>
                        {{ booking.vessel.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <u style="cursor: pointer" @click="voyageNumberModal = true"
                          v-if="booking.mainCarriageConveyanceNumber">Voyage No:
                          {{ booking.mainCarriageConveyanceNumber }}</u>
                        <u style="cursor: pointer" @click="voyageNumberModal = true" v-else>No Voyage No</u>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-content>
                        <v-list-item-title>Add Vessel</v-list-item-title>
                        <v-list-item-subtitle><i>(Required)</i></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon color="teal" @click="openSailingSchedules()">
                              <v-icon>event</v-icon></v-btn>
                          </template>
                          <span style="font-size: 12px">Search Sailing Schedules</span>
                        </v-tooltip>
                        <!-- <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="blue"
                              icon
                              @click="vesselModal = true"
                            >
                              <v-icon>manage_search</v-icon>
                              </v-btn
                            >
                          </template>
                          <span style="font-size: 12px">Search Vessels</span>
                        </v-tooltip> -->
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="shippingLineModal = true" v-if="booking.modeOfTransport == 'OCEAN'">
                    <v-list-item-action v-if="!booking.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar color="white" size="36" v-if="booking.shippingLine">
                        <v-img v-if="booking.shippingLine && booking.shippingLine.logo
                          " :src="booking.shippingLine.logo" contain></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.shippingLine">{{
                        booking.shippingLine.name
                      }}</v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="booking.vessel" @click="editContractNumber()">
                    <v-list-item-action>
                      <v-icon :color="booking.contractNumber ? 'success' : 'secondary'
                        ">description</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.contractNumber">
                        {{ booking.contractNumber }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        No Contract Selected
                      </v-list-item-title>
                      <!-- <v-list-item-subtitle
                        v-if="suggestedDealTerms.length > 0"
                      >
                        {{ suggestedDealTerms.length }} Suggested Contract(s)
                      </v-list-item-subtitle> -->
                      <v-list-item-subtitle v-if="booking.contractOwner">
                        <v-icon class="mr-2" small color="grey">groups</v-icon>
                        {{ getContractParty(booking.contractOwner) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Selected Contract
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <div>
                    <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-action>
                            <v-icon :color="booking.etd ? 'success' : 'secondary'">today</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="booking.etd">
                              {{ formatDate(booking.etd) }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Estimated Departure <i>(Required)</i>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-date-picker v-model="booking.etd" no-title scrollable>
                      </v-date-picker>
                    </v-menu>
                    <!-- <v-col cols="12" sm="12"> -->
                    <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-action>
                            <v-icon :color="booking.eta ? 'success' : 'secondary'">today</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="booking.eta">
                              {{ formatDate(booking.eta) }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Estimated Arrival
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-date-picker v-model="booking.eta" no-title scrollable>
                      </v-date-picker>
                    </v-menu>
                    <!-- </v-col> -->
                  </div>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              " outlined>
              <v-card-text>
                <v-list dense>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">tag</v-icon> References
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-row class="mt-2">
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field dense outlined hide-details style="height: 40px" label="Carrier Ref No"
                          v-model="booking.carrierReferenceNumber" clearable></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field dense outlined hide-details style="height: 40px" label="Shipper Ref No"
                          v-model="booking.shipperReferenceNo" clearable></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field dense outlined style="height: 40px" hide-details label="Consignee Ref No"
                          v-model="booking.consigneeReferenceNo" clearable></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field dense outlined style="height: 40px" hide-details label="CBR Number"
                          v-model="booking.ucrNo" clearable></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" md="6" class="my-1 py-0">
                      <v-text-field dense outlined style="height: 40px" hide-details label="QX Reference"
                        v-model="booking.qxReference" clearable :disabled="true"></v-text-field>
                    </v-col> -->
                    </v-row>
                  </v-list>

                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">insights</v-icon>
                    Miscellaneous</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item @click="bookingOfficeModal = true">
                    <v-list-item-action>
                      <v-icon :color="!booking.bookingOffice ? 'secondary' : 'success'
                        ">business</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.bookingOffice">
                        {{ booking.bookingOfficeName }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Carrier Booking Office
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon :color="'secondary'">warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Hazardous Content
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="booking.isHazardous" color="blue" readonly></v-switch>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="caProductModal = true">
                    <v-list-item-action>
                      <v-icon :color="!booking.caProduct ? 'secondary' : 'grey'">thermostat_auto</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.caProduct">
                        {{ booking.caProduct }} <v-chip class="ml-2" v-if="booking.oxygen" small outlined
                          style="border:none">
                          <span style="font-weight: bold" class="mr-2">O²</span> {{ booking.oxygen }}%
                        </v-chip>
                        <v-chip class="ml-2" small outlined style="border:none" v-if="booking.carbonDioxide">
                          <span style="font-weight: bold" class="mr-2">CO²</span> {{ booking.carbonDioxide }}%
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Controlled Atmosphere
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :key="regimeKey" v-if="booking.regime">
                    <v-list-item-action class="mr-0 pr-0">
                      <v-btn text @click="getRegimeCodes(true)" rounded> {{
                        booking.regime.code
                      }}</v-btn>
                    </v-list-item-action>
                    <v-list-item-content class="ml-0 pl-0">
                      <v-list-item-subtitle>
                        <v-icon color="#007d5c" small class="mr-2">thermostat</v-icon> Set Temp:
                        {{ `${booking.regime.setPointTemp}°C` }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="booking.regime.protocol">
                        <v-icon color="#9d904a" small class="mr-2">thermostat</v-icon> Protocol:
                        {{ `${booking.regime.protocol}°C` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- <v-list-item-content style="max-width: 180px">
                          <v-select hide-details dense outlined rounded v-model="booking.vents" :suffix="booking.vents != 'Closed' && booking.vents != 'Fully Open' && booking.vents != 'MA' && booking.vents != 'CA' && booking.vents != 'Auto'?'CBM':''" label="Vents" :items="ventSettings" @change="$emit('updateVents')"></v-select>
                        </v-list-item-content> -->
                    <v-list-item-content style="max-width: 180px" 
                      v-if="booking.serviceType !='BREAKBULK' && (!booking.caProduct || (booking.caProduct && booking.caProduct == 'MAXTEND'))">
                      <v-select hide-details dense outlined rounded v-model="booking.vents"
                        :suffix="booking.vents != 'Closed' && booking.vents != 'Fully Open' && booking.vents != 'MA' && booking.vents != 'CA' && booking.vents != 'Auto' ? 'CBM' : ''"
                        label="Vents" :items="ventSettings" @change="$emit('updateVents')"></v-select>
                    </v-list-item-content>
                    <v-list-item-action v-else-if="booking.serviceType !='BREAKBULK'">
                      <v-chip>Closed Vents</v-chip>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="danger" v-on="on" @click="removeRegime">
                            <v-icon>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Remove Regime</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-else @click="getRegimeCodes(true)">
                    <v-list-item-content class="text-center">
                      <v-list-item-title style="color: grey">
                        No Regime Code
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list dense subheader v-if="booking.bookingPaymentDetails">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 16px">
                      <v-icon class="mr-2" color="grey">payments</v-icon>
                      Payment Terms
                      <v-btn class="ml-1" icon @click="addPayment()"
                        color="primary"><v-icon>add_circle_outline</v-icon></v-btn>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="booking.bookingPaymentDetails.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No payment details listed</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="payment in booking.bookingPaymentDetails" :key="payment.index">
                      <v-list-item-content>
                        <v-list-item-title>
                          <b>{{ findChargeType(payment.chargeType) }}</b>
                          paid by <b>{{ payment.paymentParty }}</b>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                          {{ payment.paymentTerm }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editPaymentItem(payment)"><v-icon small>edit</v-icon></v-btn>
                          <v-btn icon small @click="deletePaymentItem(payment)" color="red"><v-icon
                              small>delete</v-icon></v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">widgets</v-icon> Cargo
                    Details
                    <v-btn icon color="primary" v-if="booking.bookingContainerItems &&
                      (booking.serviceType != 'LCL' ||
                        (booking.serviceType == 'LCL' &&
                          booking.bookingContainerItems.length == 0))
                      " @click="addContainerDetails()">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div :key="profileDetailKey">
                      <v-tooltip top v-if="profileDetail &&
                        profileDetail.bookingContainerItems &&
                        profileDetail.bookingContainerItems.length > 0
                        ">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="orange" @click="containerDialog = true">
                            <v-icon>note_add</v-icon>
                          </v-btn>
                        </template>
                        <span>Add cargo settings from profile </span>
                      </v-tooltip>
                    </div>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-for="(container, index) in booking.bookingContainerItems" :key="index">
                    <v-list-item-action>
                      <v-chip>{{ container.quantity }}</v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          getContainerDescription(container.containerTypeCode)
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="container.bookingCargoItems &&
                          container.bookingCargoItems.length > 0
                          ">
                        <v-icon left small color="grey">category</v-icon>Products:
                        {{
                          container.bookingCargoItems
                            .map((x) => x.product.name)
                            .join(", ")
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        <v-icon left small color="grey">category</v-icon>No
                        Products
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon left small color="grey">local_shipping</v-icon>
                        <span v-if="container.isPrivateHaulage">
                          <span v-if="container.transportCoordinator">
                            {{ container.transportCoordinator.name }}</span>
                          <span v-else>Merchant</span>
                        </span>
                        <span v-else-if="container.isRailage">Rail</span>
                        <span v-else-if="container.isCarrierHaulage">Carrier</span>
                        <span v-else>N/A</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="container.temporaryProducts &&
                        container.temporaryProducts.length > 0
                        " dense>
                        <div style="margin-top: 3px">
                          <v-list-item>
                            <div v-for="(product, index) in mapTemporaryProducts(
                              container.temporaryProducts
                            )" :key="index">
                              <v-btn icon outlined small :color="product.color" class="my-1">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon small v-on="on">{{
                                      product.icon
                                    }}</v-icon>
                                  </template>
                                  <span style="white-space: pre-line;">{{ product.product }}
                                  </span>
                                </v-tooltip>
                              </v-btn>
                            </div>
                          </v-list-item>
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-wrap">
                        {{ container.comment }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mx-0 px-0">
                      <v-row justify="center">
                        <v-btn icon small @click="editContainerItem(container, index, 'Create')"><v-icon
                            small>edit</v-icon></v-btn>
                        <v-btn icon small @click="deleteContainerItem(index)" color="red"><v-icon
                            small>delete</v-icon></v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="!booking.bookingContainerItems ||
                    booking.bookingContainerItems.length == 0
                    " class="text-center">
                    <v-list-item-content>
                      <span style="color: grey"> No container items </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="customerSearchModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="relatedLoading">
        <v-card-title>
          Search Customer
          <v-spacer></v-spacer>
          <v-btn text @click="customerSearchModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field placeholder="Search" autofocus prepend-inner-icon="search" v-model="searchCustomer" outlined dense
            clearable hide-details></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item v-for="organisation in filterOrganisations" :key="organisation.id"
              :disabled="organisation.clientStatus == 'ON HOLD' || organisation.clientStatus == 'INACTIVE'"
              @click="setCustomer(organisation)">
              <v-list-item-action>
                <v-avatar size="40" v-if="organisation.relatedOrganisation.logo" color="white">
                  <v-img :src="organisation.relatedOrganisation.logo" contain></v-img>
                </v-avatar>
                <v-avatar size="40" v-else color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name ? organisation.relatedOrganisation.name.charAt(0) : '' }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias && organisation.relatedOrganisation.alias != organisation.relatedOrganisation.name">
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="organisation.clientStatus">
                  <v-chip x-small outlined style="border:none">
                    <v-icon small left
                      :color="getClientStatusColor(organisation.clientStatus)">fiber_manual_record</v-icon>
                    {{ organisation.clientStatus }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey">No organisations found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="haulierModal" width="900px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-card-title>
          Manage Haulier
          <v-spacer></v-spacer>
          <v-btn text @click="haulierModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-subheader style="font-size: 16px">Transport Type</v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="haulier in hauliers" :key="haulier.index" @click="setHaulier(haulier)">
                  <v-list-item-action>
                    <v-avatar color="white" size="36">
                      <v-img v-if="haulier.logo" :src="haulier.logo" contain></v-img>
                      <v-icon v-else color="black">{{ haulier.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ haulier.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      haulier.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="success" v-if="containerItem[haulier.flag]">check_circle_outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense :key="preadviseKey">
                <v-subheader style="font-size: 16px">Transport Coordinator</v-subheader>
                <v-divider></v-divider>
              </v-list>
              <v-select :items="selectableParties" :disabled="containerItem.isCarrierHaulage" :placeholder="containerItem.isCarrierHaulage
                ? 'Select Coordinator'
                : 'Not Applicable'
                " item-text="name" item-value="id" rounded style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
                hide-details outlined return-object v-model="containerItem.transportCoordinator" @change="setTransporter"
                clearable dense>
                <template v-slot:selection="data">
                  <v-row align="center" v-if="containerItem.isPrivateHaulage || containerItem.isRailage">
                    <v-avatar size="42" class="mr-2" v-if="data.item">
                      <v-img v-if="data.item.icon" contain :src="data.item.icon"></v-img>
                    </v-avatar>
                    <span v-if="data.item">
                      {{ data.item.name }}
                    </span>
                  </v-row>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-list-item dense v-if="
                booking.movementType == 'EXPORT'
                " class="mt-2">
                <v-list-item-action>
                  <v-icon color="secondary">article</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Pre-Advise </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="preadviseKey">
                  <v-switch color="success" v-model="containerItem.preAdviseRequired"></v-switch>
                </v-list-item-action>
              </v-list-item>

              <!-- <v-list-item
                dense
                v-if="
                  (containerItem.isPrivateHaulage ||
                  containerItem.isCarrierHaulage) &&
                  booking.movementType == 'EXPORT'
                "
                class="mt-2"
              >
                <v-list-item-action>
                  <v-icon color="secondary">electrical_services</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Plugged-In </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="pluggedInKey">
                  <v-switch
                    color="success"
                    v-model="containerItem.pluggedInRequired"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item> -->

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select shipment profile -->
    <v-dialog v-model="shipmentProfileDialog" width="90vw" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="settingShipmentProfile">
        <v-card-title>
          Search Shipment Profiles
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field placeholder="Search" autofocus prepend-inner-icon="search" v-model="searchProfiles" outlined
                dense clearable hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-data-table id="profile-info" style="cursor: pointer; font-size: 12px" :items="filteredProfiles" dense
            :loading="loadingShipmentProfiles" :headers="shipmentProfileHeaders" fixed-header height="70vh"
            @click:row="setShipmentProfile">
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'Draft'" color="blue" class="white--text" small>Draft</v-chip>
              <v-chip v-else-if="item.status == 'Approved'" color="success" class="white--text" small>Approved</v-chip>
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span>
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip color="success" pill outlined small v-if="item.allOriginPorts">
                <v-icon small left>check</v-icon> All Ports</v-chip>
              <div v-else>
                <span v-for="(port, i) in item.consigneeProfilePorts.filter(
                  (x) => x.type == 'loading'
                )" :key="port.id">
                  <span v-if="i > 0">, </span>{{ port.name }}</span>
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip color="success" pill outlined small v-if="item.allDestinationPorts">
                <v-icon small left>check</v-icon> All Ports</v-chip>
              <div v-else>
                <span v-for="(port, i) in item.consigneeProfilePorts.filter(
                  (x) => x.type == 'discharge'
                )" :key="port.id">
                  <span v-if="i > 0">, </span>{{ port.name }}</span>
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img contain v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="item.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img contain v-if="item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip small outlined class="mt-1" v-for="term in item.consigneeProfileIncoTerms" :key="term.id">{{
                term.incoTerm }}</v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="caProductModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Controlled Atmosphere</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon color="secondary" @click="setCAProduct()"
            ><v-icon>save</v-icon></v-btn
          > -->
          <v-btn text @click="caProductModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete v-model="booking.caProduct" :items="caProducts" outlined dense placeholder="Select a CA Product"
            clearable @change="setCAProduct()" item-text="description" item-value="name"></v-autocomplete>
          <v-text-field label="O²" outlined dense v-model="booking.oxygen" type="number" @input="setCASettings()"
            suffix="%"></v-text-field>
          <v-text-field label="CO²" outlined dense v-model="booking.carbonDioxide" type="number" @input="setCASettings()"
            suffix="%"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit container item details -->
    <v-dialog v-model="containerItemModal" width="95vw" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transaprent">
          <v-toolbar-title v-if="containerItem.type == 'CONTAINER'">
            Manage Container Item
          </v-toolbar-title>
          <v-toolbar-title v-else> Manage Breakbulk Item </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="containerItem.saved" icon color="red"
            @click="deleteContainerItem(containerItem.index)"><v-icon>delete</v-icon></v-btn>
          <!-- <v-btn color="secondary" @click="saveContainerItem" icon
            :disabled="(!containerItem.isCarrierHaulage && !containerItem.isRailage) && (!containerItem.transportCoordinator || !containerItem.transportCoordinator.name) || (availableProducts.monitoringService === 'TempCheck' || availableProducts.monitoringService === 'TempCheckPLUS') && !availableDevices.some(device => device.count>0)"
            :loading="savingContainerItem"><v-icon>save</v-icon></v-btn> -->
            <template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
      <v-btn icon color="secondary" @click="saveContainerItem"
        :disabled="((!containerItem.isCarrierHaulage ) && (!containerItem.transportCoordinator || !containerItem.transportCoordinator.name)) ||
        (availableProducts.monitoringService === 'TempCheck' || availableProducts.monitoringService === 'TempCheckPLUS') && !availableDevices.some(device => device.count>0)"
        :loading="savingContainerItem" >
        <v-icon>save</v-icon>
      </v-btn>
      </div>
    </template>
    <span v-if="((!containerItem.isCarrierHaulage ) && (!containerItem.transportCoordinator || !containerItem.transportCoordinator.name)) ||
        (availableProducts.monitoringService === 'TempCheck' || availableProducts.monitoringService === 'TempCheckPLUS') && !availableDevices.some(device => device.count>0)">
      <div>
        <span style="font-size: 12px" class="text-decoration-underline" > Reasons why button is disabled: </span>
      </div>
      <!-- Condition 1: No transport coordinator selected -->
      <div v-if="(!containerItem.isCarrierHaulage ) && (!containerItem.transportCoordinator || !containerItem.transportCoordinator.name)">
        <span style="font-size: 12px; color:#ff6666">No transport coordinator selected</span>
      </div>
      <!-- Condition 2: Monitoring service selected but no devices -->
      <div v-if="(availableProducts.monitoringService === 'TempCheck' || availableProducts.monitoringService === 'TempCheckPLUS') && !availableDevices.some(device => device.count>0)">
        <span style="font-size: 12px; color:#ff6666">{{availableProducts.monitoringService}} service was selected, but no devices is selected</span>
      </div>

    </span>
  </v-tooltip>
</template>
            
          <v-btn text @click="exitContainerItemModal()">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="4" class="pt-1">
              <v-row class="mt-1 mb-2">
                <v-col cols="6">
                  <v-text-field label="Customer Ref" v-model="containerItem.customerContainerRef" outlined dense
                    hide-details clearable></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select outlined v-if="booking.movementType == 'EXPORT'" label="VGM Method"
                    :items="['Method 1', 'Method 2']" dense hide-details clearable
                    v-model="containerItem.verificationMethod"></v-select>
                </v-col>
              </v-row>
              <v-list dense v-if="containerItem.type == 'CONTAINER'">
                <v-list-item dense>
                  <v-list-item-action>
                    <el-input-number class="my-0 py-0" :min="1" :disabled="booking.serviceType == 'LCL'"
                      :value="containerItem.quantity" size="mini" v-model="containerItem.quantity"></el-input-number>
                  </v-list-item-action>
                  <v-autocomplete class="my-0 py-0" hide-details outlined dense clearable label="Container Type"
                    v-model="containerItem.containerTypeCode" @change="containerTypeKey++" :items="containerTypes"
                    item-text="shortDescription" item-value="typeCode">
                  </v-autocomplete>
                </v-list-item>
              </v-list>

              <v-list dense subheader v-if="containerItem.bookingCargoItems">
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">Cargo/Products
                  <v-btn icon color="primary" @click="addContainerCargoDetails">
                    <v-icon>add_circle_outline</v-icon></v-btn>
                  <!-- <v-spacer></v-spacer> -->
                </v-subheader>
                <v-divider></v-divider>

                <v-list-item v-if="!containerItem.bookingCargoItems ||
                  (containerItem.bookingCargoItems &&
                    containerItem.bookingCargoItems.length == 0)
                  ">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No products listed</span>
                  </v-list-item-content></v-list-item>
                <v-list-item v-for="(cargo, index) in containerItem.bookingCargoItems" :key="index">
                  <v-list-item-content>
                    <v-list-item-title v-if="cargo.product">
                      {{ cargo.product.name }}
                      <!-- <v-row align="center" class="my-2">
                        
                      </v-row> -->
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ cargo.hsCode }}
                      <span v-if="cargo.pallets">
                        - {{ cargo.pallets }} PALLETS</span>
                      <span v-if="cargo.quantity">
                        - {{ cargo.quantity }} {{ cargo.quantityType }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="cargo.grossWeight || cargo.nettWeight || cargo.volume
                      " class="text-wrap">
                      NETT {{ cargo.nettWeight }} KG - GROSS
                      {{ cargo.grossWeight }} KG - {{ cargo.volume }} CBM
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center">
                      <v-btn icon small @click="editCargoItem(cargo)"><v-icon small>edit</v-icon></v-btn>
                      <v-btn icon small @click="deleteCargoItem(index)" color="red"><v-icon small>delete</v-icon></v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item @click="getRegimeCodes(true)" :disabled="!containerItem.bookingCargoItems ||
                  (containerItem.bookingCargoItems &&
                    containerItem.bookingCargoItems.length == 0)
                  ">
                  <v-list-item-action>
                    <v-icon :color="!booking.regimeCode ? 'secondary' : 'success'">tag</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.regimeCode">
                      {{ booking.regimeCode }}
                      <v-chip class="ml-2" outlined small v-if="booking.regime && booking.regime.steri">
                        <v-icon left color="blue" small>ac_unit</v-icon>Steri
                        Code
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle v-if="booking.regime">
                      <v-icon class="mr-2" color="grey" small>thermostat</v-icon>Set Point: {{ booking.regime.setPointTemp
                      }}°C
                      <v-icon class="mx-2" color="red" small>thermostat</v-icon>Max: {{ booking.regime.maxProductTemp }}°C
                      <v-icon class="mx-2" color="blue" small>thermostat</v-icon>Min {{ booking.regime.minProductTemp }}°C
                    </v-list-item-subtitle>
                    <v-list-item-subtitle> Regime Code </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-textarea v-model="containerItem.comment" label="Comments" outlined hide-details rows="3"></v-textarea>
            </v-col>

            <v-col cols="12" sm="12" md="4" :key="containerTypeKey">
              <v-list dense subheader>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">
                  Additional Products & Services
                  <v-spacer></v-spacer>
                  <v-btn icon color="primary" @click="getTemporaryProducts()">
                    <v-icon>add_circle_outline</v-icon></v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <v-list dense class="mt-0 pt-0">
                  <v-list-item v-if="!hasProductsCount && !hasDevicesWithCount && !availableProducts.monitoringService">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No products & services listed</span>
                    </v-list-item-content>
                  </v-list-item>

                  <template v-if="hasProductsCount">
                    <v-list-item v-for="item in hasProducts" :key="generateKey(item)">
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="10" class="py-4">
                            {{ item.product }}
                          </v-col>
                          <v-col cols="2">
                            <v-list-item-action class="py-0">
                              <v-btn-toggle :key="gensetToggleKey">
                                <v-btn x-small @click="toggleProducts('NO', item.product)" :color="item.selected === false ? 'primary' : 'grey'
                                  ">
                                  NO
                                </v-btn>
                                <v-btn x-small @click="toggleProducts('YES', item.product)" :color="item.selected === true ? 'tertiary' : 'grey'
                                  ">
                                  YES
                                </v-btn>
                              </v-btn-toggle>
                            </v-list-item-action>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                      <v-list-item-action></v-list-item-action>
                    </v-list-item>
                  </template>

                  <template v-if="hasDevicesWithCount">
                    <v-list-item v-for="item in hasDevices" :key="generateKey(item)">
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="9" class="py-4">
                            {{ item.product }}
                            {{
                              item.variation !== "Standard"
                              ? "(" + item.variation + ")"
                              : ""
                            }}
                          </v-col>
                          <v-col cols="3">
                            <v-list-item-action class="py-0">
                              <el-input-number :min="0" :value="item.count" size="mini" :max="20" v-model="item.count"
                                @input="checkItemCount(item)"></el-input-number>
                            </v-list-item-action>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-if="availableProducts.monitoringService">
                    <v-list-item>
                      <v-list-item-content>
                        <v-row no-gutters>
                          <v-col cols="9" class="py-4">
                            {{ `Monitoring Service - ` + availableProducts.monitoringService }}
                          </v-col>
                          <v-col cols="3">

                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
                <!-- <v-list dense class="mt-0 pt-0">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>bolt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="text-wrap">
                      <v-list-item-title> Genset Required </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="containerItem.gensetRequired"
                        color="success"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item style="height: 35px">
                    <v-list-item-action>
                      <v-icon>thermostat</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Add Temperature Device
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="containerItem.sensorDevice"
                        color="success"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    style="height: 35px"
                    v-if="containerItem.sensorDevice"
                  >
                    <v-select
                      class="mr-1"
                      label="Monitoring Service"
                      persistent-placeholder
                      clearable
                      flat
                      dense
                      outlined
                      hide-details
                      :items="['TempCheck', 'TempCheckPLUS', 'Port Monitoring']"
                      v-model="containerItem.sensorService"
                    ></v-select>
                    <v-select
                      class="ml-1"
                      label="Temperature Device Type"
                      persistent-placeholder
                      clearable
                      flat
                      hide-details
                      dense
                      outlined
                      :items="[
                        'RF TEMPTALE',
                        'USB TEMPTALE',
                        'GEO EAGLE TEMPTALE',
                        'MOST TEMPTALE',
                        '2G GEO TEMPTALE',
                        '3G GEO TEMPTALE',
                        '4G GEO TEMPTALE',
                        '2G TIVE',
                        '5G TIVE',
                      ]"
                      v-model="containerItem.sensorDeviceType"
                    ></v-select>
                  </v-list-item>
                </v-list> -->
                <v-list v-if="displayReeferSettings && containerItem.type == 'CONTAINER'
                  ">
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2">ac_unit</v-icon> Reefer
                    Settings</v-subheader>
                  <v-divider></v-divider>
                  <v-row class="mt-2">
                    <v-col cols="12" sm="4" class="my-0 py-0">
                      <v-select  v-if="booking.serviceType !='BREAKBULK'" label="Vents" outlined dense hide-details class="pb-1" v-model="containerItem.vents"
                        :items="ventSettings"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" class="my-0 py-0">
                      <v-text-field prepend-inner-icon="thermostat" label="Set Temp" hide-details
                        v-model="containerItem.setPointTemp" outlined dense class="pb-1" suffix="°C"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="my-0 py-0">
                      <v-text-field label="Humidity" v-model="containerItem.humidity" outlined hide-details dense
                        type="number" suffix="%"></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="4">
                      <v-text-field
                        label="Oxygen"
                        v-model="containerItem.oxygen"
                        outlined
                        dense
                        v-if="booking.caProduct"
                        hide-details
                        class="pb-1"
                        type="number"
                        suffix="%"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Carbon Dioxide"
                        v-model="containerItem.carbonDioxide"
                        v-if="booking.caProduct"
                        hide-details
                        class="pb-1"
                        outlined
                        type="number"
                        dense
                        suffix="%"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"> -->
                    <!-- <v-text-field
                        label="Nitrogen"
                        v-model="containerItem.nitrogen"
                        outlined
                        v-if="booking.caProduct"
                        hide-details
                        dense
                        type="number"
                        suffix="%"
                      ></v-text-field> -->
                    <!-- </v-col> -->
                  </v-row>
                </v-list>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="pt-1">
              <v-list dense subheader :key="milestoneKey">
                <v-list-item @click="partyType = 'Buyer', partyModal = true">
                  <v-list-item-action>
                    <v-avatar :color="containerItem.buyer && containerItem.buyer.logo ? 'white' : 'secondary'" size="32"
                      v-if="containerItem.buyer">
                      <v-img v-if="containerItem.buyer.logo" :src="containerItem.buyer.logo" contain></v-img>
                      <h3 v-else style="color: white">{{ containerItem.buyer.name ? containerItem.buyer.name.charAt(0) :
                        '' }}</h3>
                    </v-avatar>
                    <v-icon v-else color="secondary">shopping_cart</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="containerItem.buyer">
                      {{ containerItem.buyer.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Preliminary Buyer</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="partyType = 'Consignee', partyModal = true">
                  <v-list-item-action>
                    <v-avatar :color="containerItem.consignee && containerItem.consignee.logo ? 'white' : 'secondary'"
                      size="32" v-if="containerItem.consignee">
                      <v-img v-if="containerItem.consignee.logo" :src="containerItem.consignee.logo" contain></v-img>
                      <h3 v-else style="color: white">{{ containerItem.consignee.name ?
                        containerItem.consignee.name.charAt(0) : '' }}</h3>
                    </v-avatar>
                    <v-icon v-else color="secondary">call_received</v-icon>
                  </v-list-item-action>
                  <v-list-item-content :key="haulierKey">
                    <v-list-item-title v-if="containerItem.consignee">
                      {{ containerItem.consignee.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Preliminary Consignee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setSelectableParties(), (haulierModal = true)" :key="haulierKey">
                  <v-list-item-action>
                    <v-avatar color="white" size="32" v-if="containerItem.transportCoordinator">
                      <v-img v-if="containerItem.transportCoordinator.logo" :src="containerItem.transportCoordinator.logo"
                        contain></v-img>
                      <v-icon small v-else color="black">{{
                        containerItem.transportCoordinator.logo
                      }}</v-icon>
                    </v-avatar>
                    <v-icon v-else color="secondary">local_shipping</v-icon>
                  </v-list-item-action>
                  <v-list-item-content :key="haulierKey">
                    <v-list-item-title v-if="containerItem.transportCoordinator">
                      {{ containerItem.transportCoordinator.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else-if="containerItem.isCarrierHaulage">Carrier Haulage</v-list-item-title>
                    <v-list-item-title v-else-if="containerItem.isRailage">Rail</v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle v-if="containerItem.preAdviseRequired
                      "><v-chip color="blue" x-small>Preadvise Required</v-chip></v-list-item-subtitle>
                    <v-list-item-subtitle>Transport Coordinator</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">Pre-Carriage Legs / Loading Points
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item v-for="(milestone, index) in availableMilestones.filter(
                  (x) => x.type == booking.movementType
                )" :key="index">
                  <v-list-item-action class="mr-0 pr-0">
                    <v-avatar size="10" v-if="containerItem[milestone.value]" :color="containerItem[milestone.value].isActual
                      ? 'success'
                      : 'secondary'
                      "></v-avatar>
                    <v-icon v-else color="grey" small>warning</v-icon>
                  </v-list-item-action>
                  <v-list-item-content v-if="containerItem[milestone.value]">
                    <v-list-item-title style="font-size: 14px">{{
                      containerItem[milestone.value].description
                    }}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px">{{
                      formatDateTime(containerItem[milestone.value].date, containerItem[milestone.value].time)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-subtitle style="font-size: 14px">{{
                      milestone.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn v-if="!containerItem[milestone.value]" icon small color="primary"
                      @click="addMilestone(containerItem, milestone.value, false)">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                    <v-row justify="center" v-if="containerItem[milestone.value]">
                      <v-btn small icon @click="addMilestone(containerItem, milestone.value, false)">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                      <v-btn small color="red" icon @click="removeMilestone(milestone.value)">
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POI Modal -->
    <v-dialog v-model="poiModal" width="400px" persistent>
      <v-card :loading="loadingFunctions">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <span v-if="loadingPointItem.type == 'emptyLoadingPoint'">Add Empty Loading Point</span>
            <span v-else-if="loadingPointItem.type == 'firstLoadingPoint'">Add 1st Loading Point</span>
            <span v-else-if="loadingPointItem.type == 'secondLoadingPoint'">Add 2nd Loading Point</span>
            <span v-else-if="loadingPointItem.type == 'dropOffPoint'">Add Port Holding</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu transition="slide-y-transition" offset-y style="min-width: 7vw" rounded="xl"
            :close-on-content-click="false">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon v-bind="attrs" v-on="{ ...menu }">
                    <v-icon v-on="{ ...tooltip }">filter_alt</v-icon>
                  </v-btn>
                </template>
                <span>Filter Functions</span>
              </v-tooltip>
            </template>
            <v-toolbar flat dense color="greyBase">Available Functions</v-toolbar>
            <v-list dense color="greyBase" rounded class="px-0 pt-0">
              <v-list-item v-for="(item, index) in poiFunctions" :key="index">
                <v-chip @click="
                  (item.selected = !item.selected), searchPois(poiSearch)
                  " dense :outlined="!item.selected" :color="item.selected ? 'tertiary' : ''"
                  :filled="item.selected">{{ item.name }}</v-chip>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon @click="poiModal = false" color="red">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-chip-group column>
            <v-chip @click="(item.selected = !item.selected), searchPois(poiSearch)" v-for="(item, index) in poiFunctions.filter(
              (func) => func.selected
            )" :key="index" filled dense small color="tertiary">{{ item.name }}</v-chip>
          </v-chip-group>
          <v-text-field hide-details autofocus outlined dense clearable v-model="poiSearch" placeholder="Search"
            prepend-inner-icon="search"></v-text-field>
          <v-list dense style="max-height: 45vh; overflow-y: auto">
            <v-progress-linear v-if="poiSearchLoading" color="primary" indeterminate></v-progress-linear>
            <v-list-item v-for="point in pointsOfInterest" :key="point.id" @click="setLoadingPoint(point)">
              <v-list-item-content>
                <v-list-item-title>{{ point.name }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 12px" class="text-wrap">
                  {{ point.address }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="point.poiFunctions && point.poiFunctions.length > 0">
                  <v-chip class="mx-1" x-small v-for="functionality in point.poiFunctions" :key="functionality.id">{{
                    functionality.functionality }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit Cargo -->
    <v-dialog v-model="cargoModal" persistent width="450px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Cargo Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :disabled="!cargoItem.product || !cargoItem.hsCode || !cargoItem.description
            " @click="saveCargoItem"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="(cargoItem = {}), (cargoModal = false)">
            X
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select hide-details class="my-2" label="Type" :items="['Product Group', 'Product', 'Variety']"
                v-model="filterProductType" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-autocomplete class="my-2" hide-details ref="countrySelect" autofocus hide-no-data hide-selected
                label="Product" dense outlined :items="filterProducts" clearable item-text="name" item-value="id"
                :menu-props="{ closeOnContentClick: true }" @change="setCargoItemDetails" v-model="cargoItem.product"
                return-object :loading="loadingProduct">
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="data.item.parentProduct" style="font-size: 12px">
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-textarea hide-details class="my-2" rows="3" label="Description" v-model="cargoItem.description"
                outlined></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="HS Code" v-model="cargoItem.hsCode" outlined
                dense></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="No Pallets" type="number" v-model="cargoItem.pallets"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Nett Weight" suffix="Kg" v-model="cargoItem.nettWeight"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Gross Weight" suffix="Kg" v-model="cargoItem.grossWeight"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Cubic measure" suffix="CBM" v-model="cargoItem.volume"
                outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" label="Batch Number" v-model="cargoItem.batchNumber" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select hide-details class="my-2" label="Quantity Type" clearable v-model="selectedQuantityType" outlined
                dense :items="productQuantityTypes" @change="selectQuantityType()" return-object></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field hide-details class="my-2" :label="`Quantity`" v-model="cargoItem.quantity" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Industrial Use" dense hide-details v-model="cargoItem.industrial"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Organic" dense hide-details v-model="cargoItem.organic"></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Hazardous" dense v-model="cargoItem.hazardous" @change="updateHazardousStatus"></v-switch>
            </v-col>
          </v-row>
          <v-list dense subheader v-if="cargoItem.hazardous">
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px">Hazardous Details</v-subheader>
            <v-divider></v-divider>
            <v-row class="mt-2">
              <v-col cols="12" sm="6" class="my-0 py-0" v-if="cargoItem.hazardous">
                <v-text-field hide-details class="my-2" label="UN Code" v-model="cargoItem.unCode" outlined
                  dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0" v-if="cargoItem.hazardous">
                <v-text-field hide-details class="my-2" label="UN Packing Group" v-model="cargoItem.unCodePackingGroup"
                  outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0" v-if="cargoItem.hazardous">
                <v-text-field hide-details class="my-2" :label="`Hazard Class`" v-model="cargoItem.class" outlined
                  dense></v-text-field>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POL Modal -->
    <v-dialog v-model="polModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POL
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="polModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field class="my-0 py-0" autofocus v-if="booking.consigneeProfile" prepend-inner-icon="search" dense
            placeholder="Search" outlined clearable v-model="searchOrigin" hide-details></v-text-field>
          <v-text-field class="my-0 py-0" autofocus v-else prepend-inner-icon="search" dense placeholder="Search" outlined
            clearable v-model="portSearch" hide-details></v-text-field>
          <div v-if="booking.consigneeProfile &&
            !booking.consigneeProfile.allOriginPorts
            ">
            <v-list dense>
              <v-list-item v-for="port in loadingPorts" :key="port.id" @click="setPort('portOfLoad', port)">
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img contain v-if="port.code" :src="`https://cdn.loglive.io/flags/4x3/${port.code
                      .substring(0, 2)
                      .toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="success" v-if="port.code == booking.portOfLoadValue">
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear v-if="loadingOriginPorts" color="primary" indeterminate>
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll v-if="filterOriginPort.length > 0" class="my-0 py-0" :bench="0"
                    :items="filterOriginPort" :key="originPortKey" height="500" item-height="50">
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item.id" @click="setPort('portOfLoad', item)">
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px; color: grey">
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn :key="originPortKey" icon @click="favouritePort(item, 'MARK', 'Origin')"
                            v-if="!item.organisationPorts[0]"><v-icon>star_border</v-icon></v-btn>
                          <v-btn :key="originPortKey" icon @click="favouritePort(item, 'UNMARK', 'Origin')"
                            v-if="item.organisationPorts[0]"><v-icon color="yellow">star</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterOriginPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">No ports found</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear v-if="loadingPortSearch" color="primary" indeterminate></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll v-if="portResults.length > 0" class="my-0 py-0" :bench="0" :items="portResults"
                    height="250" item-height="50">
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item.id" @click="setPort('portOfLoad', item)">
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px; color: grey">
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px">No results found</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POD Modal -->
    <v-dialog v-model="podModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POD
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="podModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field class="my-0 py-0" autofocus v-if="booking.consigneeProfile" prepend-inner-icon="search" dense
            placeholder="Search" outlined clearable v-model="searchDestination" hide-details></v-text-field>
          <v-text-field class="my-0 py-0" v-else autofocus prepend-inner-icon="search" dense placeholder="Search" outlined
            clearable v-model="portSearch" hide-details></v-text-field>
          <div v-if="booking.consigneeProfile &&
            !booking.consigneeProfile.allDestinationPorts
            ">
            <v-list dense>
              <v-list-item v-for="port in dischargePorts" :key="port.id" @click="setPort('portOfDischarge', port)">
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img contain v-if="port.code" :src="`https://cdn.loglive.io/flags/4x3/${port.code
                      .substring(0, 2)
                      .toLowerCase()}.svg`"></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="success" v-if="port.code == booking.portOfDischargeValue">
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear v-if="loadingDestinationPorts" color="primary" indeterminate>
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll v-if="filterDestinationPort.length > 0" class="my-0 py-0" :bench="0"
                    :items="filterDestinationPort" :key="destinationPortKey" height="500" item-height="50">
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item.id" @click="setPort('portOfDischarge', item)">
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px; color: grey">
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn :key="originPortKey" icon @click="favouritePort(item, 'MARK', 'Destination')"
                            v-if="!item.organisationPorts[0]"><v-icon>star_border</v-icon></v-btn>
                          <v-btn :key="originPortKey" icon @click="
                            favouritePort(item, 'UNMARK', 'Destination')
                            " v-if="item.organisationPorts[0]"><v-icon color="yellow">star</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterDestinationPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">No ports found</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear v-if="loadingPortSearch" color="primary" indeterminate></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll v-if="portResults.length > 0" class="my-0 py-0" :bench="0" :items="portResults"
                    height="250" item-height="50">
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item.id" @click="setPort('portOfDischarge', item)">
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px; color: grey">
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px">No results found</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Final Destination Modal -->
    <v-dialog v-model="fdModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Final Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(fdModal = false), (portSearch = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-container class="pa-0">
              <!-- Chips Container -->
              <v-chip-group v-model="selectedTransportModes" active-class="primary--text" multiple>
                <v-chip v-for="transport in transportModes" :key="transport" :value="transport" filter small>
                  {{ transport }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </template>
          <v-text-field class="my-0 py-0" autofocus prepend-inner-icon="search" dense placeholder="Search" outlined
            clearable v-model="portSearch" hide-details></v-text-field>
          <v-progress-linear v-if="loadingPortSearch" color="primary" indeterminate></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll v-if="portResults.length > 0" class="my-0 py-0" :bench="0" :items="portResults"
                  height="250" item-height="50">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setPort('finalDestination', item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px; color: grey">
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px">No results found</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Booking Office Modal -->
    <v-dialog v-model="bookingOfficeModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Booking Office
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bookingOfficeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field class="my-0 py-0" autofocus prepend-inner-icon="search" dense placeholder="Search" outlined
            clearable v-model="bookingOfficeSearch" hide-details></v-text-field>
          <v-progress-linear v-if="bookingOfficeLoading" color="primary" indeterminate></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll v-if="bookingOfficePlaces.length > 0" class="my-0 py-0" :bench="0"
                  :items="bookingOfficePlaces" height="250" item-height="50">
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setBookingOffice(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                            .substring(0, 2)
                            .toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px; color: grey">
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px">No results found</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="countryModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Select {{ countryType }} Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(countryModal = false), (searchCountries = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field outlined autofocus dense hide-details v-model="searchCountries" clearable placeholder="Search"
            prepend-inner-icon="search"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="country in filterCountries" :key="country.id" @click="setCountry(country)">
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img contain v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No countries found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="partyModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card :loading="loadingLinkedOrganisations">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(partyModal = false), (searchOrganisation = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field outlined dense placeholder="Search" prepend-inner-icon="search" v-model="searchOrganisation"
            clearable></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="organisation in filterCustomerOrganisations" :key="organisation.id"
              @click="setParty(organisation.relatedOrganisation)">
              <v-list-item-action>
                <v-avatar v-if="organisation.relatedOrganisation.logo" color="white">
                  <v-img :src="organisation.relatedOrganisation.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3 style="color: white">{{ organisation.relatedOrganisation.name ?
                    organisation.relatedOrganisation.name.charAt(0) : '' }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="organisation.relatedOrganisation.alias">
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sailingScheduleModal" persistent width="90vw" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip v-if="booking.portOfLoadValue" small outlined style="border: none" class="ml-2">
            <v-avatar size="24" left>
              <v-img contain v-if="booking.portOfLoadValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                .substring(0, 2)
                .toLowerCase()}.svg`"></v-img>
            </v-avatar>
            {{ booking.portOfLoadValue }}
          </v-chip>

          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip v-if="booking.portOfDischargeValue" small outlined style="border: none">
            <v-avatar size="24" left>
              <v-img contain v-if="booking.portOfDischargeValue" :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                .substring(0, 2)
                .toLowerCase()}.svg`"></v-img>
            </v-avatar>
            {{ booking.portOfDischargeValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="sailingScheduleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal :export="booking.movementType == 'EXPORT'" :portOfLoadValue="booking.portOfLoadValue"
            :finalDestinationValue="booking.portOfDischargeValue" :shippingLines="shippingLines"
            @select="selectSchedule" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-model="vesselModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="vesselLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Search Vessel </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="vesselModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            v-model="searchVessel"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="vessel in vessels"
              :key="vessel.id"
              @click="setVessel(vessel)"
            >
              <v-list-item-avatar>
                <v-img
                  contain
                  v-if="vessel.countryCode"
                  :src="`https://cdn.loglive.io/flags/4x3/${vessel.countryCode.toLowerCase()}.svg`"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ vessel.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="vessel.imoNumber"
                  >IMO: {{ vessel.imoNumber }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="vessel.mmsiNumber"
                  >MMSI: {{ vessel.mmsiNumber }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="vessels.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No vessels found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog
      v-model="voyageNumberModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="voyageNumberModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Voyage No"
            v-model="booking.mainCarriageConveyanceNumber"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-------------------------------------------------------- ORIGIN & DESTINATION -------------------------------------------------------->
    <v-dialog v-model="paymentModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Payment Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" icon :disabled="!paymentItem.chargeType ||
            !paymentItem.paymentTerm ||
            !paymentItem.paymentParty
            " @click="savePaymentItem"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="(paymentModal = false), (paymentItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select v-model="paymentItem.chargeType" outlined dense label="Charge Type*" item-text="value"
            item-value="key" :items="paymentTypes"></v-select>
          <v-select v-model="paymentItem.paymentTerm" outlined dense label="Payment Term*" item-text="value"
            item-value="key" :items="paymentTerms"></v-select>
          <v-select v-model="paymentItem.paymentParty" outlined dense label="Payer*" :items="availableParties"></v-select>
          <v-autocomplete hide-no-data dense class="mt-2" outlined clearable :loading="paymentTownLoading"
            :search-input.sync="searchTowns" v-model="paymentItem.paymentLocation"
            :menu-props="{ closeOnContentClick: true }" label="Payment Location" :items="paymentTowns" item-text="name"
            item-value="locode" :filter="filterObject">
            <template v-slot:selection="data">
              <span style="font-size: 12px">{{ data.item.name }} ({{ data.item.locode }})</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="regimeCodeModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile" peristent>
      <v-card :loading="regimeCodeLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Regime Code </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="regimeCodeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field placeholder="Search" autofocus prpend-inner-icon="search" v-model="regimeCodeSearch" outlined
            dense clearable></v-text-field>
          <v-virtual-scroll v-if="filterRegimeCodes.length > 0" class="my-0 py-0" :bench="0" :items="filterRegimeCodes"
            height="500" item-height="85">
            <template v-slot:default="{ item }">
              <v-list-item @click="setRegimeCode(item)" style="border-bottom: 0.5px solid grey">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon>Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon>Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon>Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No regime codes found</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="incoTermModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" peristent>
  <v-card>
    <v-toolbar flat color="transparent">
      <v-toolbar-title> Set Inco Term </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="incoTermModal = false">X</v-btn>
    </v-toolbar>
    <v-card-text>
      <v-list dense style="max-height: 50vh; overflow-y: auto">
        <v-list-item v-for="code in incoTerms" :key="code.id" @click="setIncoTerm(code)" style="height: 45px">
          <v-list-item-content>
            <v-list-item-title>
              {{ code.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ code.abbreviation }}
            </v-list-item-subtitle>
            <!-- To show shipping line if available -->
            <v-list-item-subtitle v-if="code.shippingLine">
              <v-icon small color="grey" class="mr-2">directions_boat</v-icon>
              {{ code.shippingLine }}
            </v-list-item-subtitle>
            
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</v-dialog>

    <v-dialog v-model="shipmentProfileConfig" width="450px" :fullscreen="$vuetify.breakpoint.mobile" peristent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Profile Configuration </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileConfig = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="booking.consigneeProfile">
          <v-list dense>
            <v-list-item v-for="term in booking.consigneeProfile.consigneeProfileIncoTerms" :key="term.id"
            :disabled="term.contractOwner !== booking.contractOwner"
              @click="setDealTerm(term)">
              <v-list-item-action>
                <v-chip>{{ term.incoTerm }}</v-chip>
              </v-list-item-action>
              <v-list-item-content>
                <!-- <v-list-item-subtitle style="text-transform: capitalize">
                  Freight Payment Term: 
                </v-list-item-subtitle> -->
                <v-list-item-subtitle style="text-transform: capitalize">
                  Freight Charge: {{ term.freightCharge }} - {{ term.paymentTerm }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="text-transform: capitalize">
                  Origin Charge: {{ term.originCharge }} - {{ term.originTerm }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="text-transform: capitalize">
                  Destination Charge: {{ term.destinationCharge }} - {{ term.destinationTerm }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="text-transform: capitalize">
                  Contract Owner: {{ term.contractOwner }} 
                </v-list-item-subtitle>
                <!-- To show shipping line if available  -->
                <v-list-item-subtitle v-if="term.shippingLine">
                Shipping Line: {{ term.shippingLine.name }}
              </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sailingScheduleError" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-text class="text-left mt-3 pt-5">
          <p style="font-size: 16px">
            Please select make sure to select the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!booking.portOfDischargeValue">
              Port of Discharge (POD)
            </li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="blue" class="mx-2" text small @click="sailingScheduleError = false">Okay</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contractModal" width="800px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Contract </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="contractModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text :key="contractKey">
          <v-row>
            <v-col cols="12" sm="8">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <v-subheader style="font-size: 16px">Available Contracts</v-subheader>
                <v-divider></v-divider>
                <v-text-field outlined dense prepend-inner-icon="search" v-model="searchContract"
                  clearable></v-text-field>
                <v-list dense style="max-height: 50vh">
                  <v-list-item v-if="filterSuggestedContracts.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey">No Contracts found</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list-item v-for="contract in filterSuggestedContracts" :key="contract.id"
                  @click="setContract(contract)">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ contract.contracts[0].contractNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="contract.contracts[0]">
                      <v-icon color="grey" small class="mr-2">tag</v-icon>
                      {{ contract.alias }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contract.contracts[0]">
                      <v-icon small color="grey" class="mr-2">event</v-icon>
                      <span>{{ contract.contracts[0].startDate }}</span>
                      <v-icon x-small class="mr-1">arrow_forward</v-icon>
                      <span>{{ contract.contracts[0].endDate }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contract.contractOwner">
                      <v-icon small color="grey" class="mr-2">group</v-icon>
                      <span v-if="contract.contractOwner.alias">{{
                        contract.contractOwner.alias
                      }}</span>
                      <span v-else>{{ contract.contractOwner.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item></v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <!-- <v-text-field label="Contract Owner" outlined dense clearable v-model="booking.contractOwner"></v-text-field> -->
                <v-select outlined dense label="Contract Owner" :items="chargeOptions" v-model="booking.contractOwner"
                  item-text="name" item-value="value" style="text-transform: capitalize">
                </v-select>
                <v-text-field label="Contract Number" outlined dense clearable
                  v-model="booking.contractNumber"></v-text-field>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="caProductModal" width="400px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Controlled Atmosphere</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="caProductModal = false"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="caProductModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            v-model="booking.caProduct"
            :items="caProducts"
            outlined
            dense
            placeholder="Select a CA Product"
            clearable
            item-text="description"
            item-value="name"
          ></v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="addMilestoneModal" width="500px">
      <ManageContainerMilestone :item="milestoneItem" :key="milestoneKey" :bookingType="booking.movementType"
        @close="addMilestoneModal = false" @unsavedItem="setMilestonePoint" />
    </v-dialog>

    <v-dialog v-model="shippingLineModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card :loading="loadingCarriers">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Shipping Line</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shippingLineModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field dense outlined rounded prepend-inner-icon="search" autofocus placeholder="Search" clearable
            v-model="searchShippingLine"></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-for="carrier in filterShippingLines" :key="carrier.id" @click="setShippingLine(carrier)">
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3>{{ carrier.name ? carrier.name.charAt(0) : '' }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="containerDialog" max-width="500px">
      <v-card v-if="profileDetail">
        <v-card-title>Select Container & Cargo<v-spacer></v-spacer>
          <v-btn @click="containerDialog = false" text>X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group v-model="selectedContainer">
              <v-list-item v-for="(
                  container, index
                ) in profileDetail.bookingContainerItems" :key="index" :value="container"
                @click="addContainerToBooking(container)">
                <v-list-item-action>
                  <v-chip>{{ container.quantity }}</v-chip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getContainerDescription(container.containerTypeCode) }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="container.bookingCargoItems &&
                    container.bookingCargoItems.length > 0
                    ">
                    <v-icon left small color="grey">category</v-icon>Products:
                    {{
                      container.bookingCargoItems
                        .map((x) => x.product.name)
                        .join(", ")
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    <v-icon left small color="grey">category</v-icon>No Products
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="container.temporaryProducts &&
                      container.temporaryProducts.length > 0
                      " dense>
                    <div style="margin-top: 5px">
                      <v-list-item>
                        <div v-for="(product, index) in mapTemporaryProducts(
                              container.temporaryProducts
                            )" :key="index">
                          <v-btn icon outlined small :color="product.color" class="my-1">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon small v-on="on">{{
                                  product.icon
                                }}</v-icon>
                              </template>
                              <span style="white-space:pre-line;">{{
                                product.product.replace(/_/g, " ") +
                                " " +
                                "Required"
                              }}
                              </span>
                            </v-tooltip>
                          </v-btn>
                        </div>
                      </v-list-item>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-wrap">
                    {{ container.comment }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <!-- <v-card-actions>
      <v-btn @click="addContainerToBooking">Add</v-btn>
      <v-btn @click="containerDialog = false">Cancel</v-btn>
    </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-dialog v-model="addServiceModal" max-width="400px" persistent>
      <v-card>
        <v-card-title>TelemPlus Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addServiceModal = false" color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list>
          <v-list-item v-for="(product, index) in availableDevices.filter(
            (x) => x.product == 'TelemPlus'
          )" :key="index">
            <v-checkbox :label="product.variation" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="temporaryProductModal" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>{{
            bulkType == "Add" ? "Add Products" : "Edit Products"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn
            icon
            @click="addProducts()"
            :loading="saveProducts"
            color="green"
          >
            <v-icon>save</v-icon>
          </v-btn> -->
          <v-btn icon @click="closeTemporaryProductModal()" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <!-- Left Side with List Items -->
            <v-col cols="4">
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>bolt</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Genset Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="gensetToggleKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Genset')" :color="availableProducts.Genset === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Genset')" :color="availableProducts.Genset === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <!-- New List Items -->
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>air</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Otflow Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="otflowKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Otflow')" :color="availableProducts.Otflow === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Otflow')" :color="availableProducts.Otflow === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>sanitizer</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Zoono Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="zoonoKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Zoono')" :color="availableProducts.Zoono === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Zoono')" :color="availableProducts.Zoono === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>power</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Void Plugs Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="voidPlugKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Void_Plugs')" :color="availableProducts.Void_Plugs === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Void_Plugs')" :color="availableProducts.Void_Plugs === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>




              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Early Arrival Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="lateArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Early_Arrival')" :color="availableProducts.Early_Arrival === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Early_Arrival')" :color="availableProducts.Early_Arrival === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense v-if="availableProducts.Early_Arrival === true">
                <v-select label="LAR description(Early Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
                    'Pending',
                    'Approved',
                    'Approved (No Charge)',
                    'Rejected',
                    'Approved (Not Used)',
                    'Not Applicable',
                  ]" v-model="earlyDescription"></v-select>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Late Arrival Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="lateArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Late_Arrival')" :color="availableProducts.Late_Arrival === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Late_Arrival')" :color="availableProducts.Late_Arrival === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense v-if="availableProducts.Late_Arrival === true">
                <v-select label="LAR description(Late Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
                    'Pending',
                    'Approved',
                    'Approved (No Charge)',
                    'Rejected',
                    'Approved (Not Used)',
                    'Not Applicable',
                  ]" v-model="lateDescription"></v-select>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>cleaning_services</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Fumigation Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Fumigation')" :color="availableProducts.Fumigation === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Fumigation')" :color="availableProducts.Fumigation === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>settings_overscan</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Container Liners Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'ContainerLiners')" :color="availableProducts.ContainerLiners === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'ContainerLiners')" :color="availableProducts.ContainerLiners === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>emergency</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Steri Insurance Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SteriInsurance')" :color="availableProducts.SteriInsurance === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'SteriInsurance')" :color="availableProducts.SteriInsurance === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>smart_screen</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Smart Fresh </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SmartFresh')" :color="availableProducts.SmartFresh === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'SmartFresh')" :color="availableProducts.SmartFresh === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>


              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>scale</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Heavy Load </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'HeavyLoad')" :color="availableProducts.HeavyLoad === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'HeavyLoad')" :color="availableProducts.HeavyLoad === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>
            </v-col>

            <!-- Right Side with Text Fields -->
            <v-col cols="4">
              <v-card outlined flat>
                <v-row no-gutters>
                  <v-col cols="11">
                    <!-- Adjust "cols" as needed -->
                    <v-list-item dense>
                      <v-select label="Monitoring Service" placeholder="Select a Monitoring Service"
                        persistent-placeholder hide-details outlined dense item-text="text" item-value="value"
                        class="py-1 mt-2 mb-2" :items="[
                          {
                            text:'iCheck',
                            value: 'iCheck'
                          },
                          {
                            text: 'TempCheck',
                            value: 'TempCheck',
                          },
                          {
                            text: 'TempCheckPLUS',
                            value: 'TempCheckPLUS',
                          },
                          // {
                          //   text: 'TempCheckLite',
                          //   value: 'TempCheckLite',
                          // },
                          {
                            text: 'Port Monitoring',
                            value: 'Port Monitoring',
                          },
                          {
                            text: 'N/A',
                            value: ''
                          }
                        ]" v-model="availableProducts.monitoringService"></v-select>
                    </v-list-item>
                  </v-col>
                  <v-col cols="1">
                    <!-- Adjust "cols" as needed -->
                    <v-btn icon color="primary" @click="addServices()" class="mt-3">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <v-card-title>
                  TelemPlus
                  <v-spacer></v-spacer>
                  <v-btn icon color="primary" class="ml-3"
                                                            @click="addServices()"><v-icon>add_circle_outline</v-icon></v-btn>
                </v-card-title> -->
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in availableDevices.filter(
                      (option) =>
                        option.count > 0 && option.product == 'TelemPlus'
                    )" :key="index">
                      <v-text-field type="number" v-model="product.count" :min="0" class="small-text-field"
                        style="text-align: center" :label="product.variation + ' ' + 'Template'"
                        @input="updateProductCount(product)" />
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-card flat outlined>
                <v-card-title>
                  Additional products <v-spacer></v-spacer> <v-btn icon color="primary"
                    @click="addAdditionalProduct = true" class="mt-3">
                    <v-icon>add_circle_outline</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in uniqueProducts.filter(
                      (option) =>
                        option.count > 0 && option.variation == 'Standard'
                    )" :key="index">
                      <v-list-item-content>
                        <v-list-item-title> {{ product.product }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <el-input-number v-model="product.count" :min="0" size="mini"
                          @change="updateProductCount(product)"></el-input-number>
                      </v-list-item-action>
                      <!-- <v-text-field
                          type="number"
                          v-model="product.count"
                          :min="0"
                          class="small-text-field"
                          style="text-align: center"
                          :label="product.variation + ' ' + 'Template'"
                          @input="updateProductCount(product)"
                        /> -->
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <!-- <v-card
                outlined
                flat
                v-for="uniqueProduct in uniqueProducts.filter((p) =>
                  ['Void Plugs', 'Blast Freezing'].includes(p)
                )"
                :key="uniqueProduct"
              >
                <v-card-title>
                  {{ uniqueProduct }}
                  <v-spacer></v-spacer>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="(product, index) in availableDevices.filter(
                        (d) => d.product === uniqueProduct
                      )"
                      :key="index"
                    >
                      <el-input-number
                        v-model="product.count"
                        :min="0"
                        controls-position="right"
                        size="mini"
                        @change="updateProductCount(product)"
                      ></el-input-number>
                      <v-text-field
                        type="number"
                        v-model="product.count"
                        :min="0"
                        class="small-text-field"
                        style="text-align: center"
                        @input="updateProductCount(product)"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-title>
                <v-card-text> </v-card-text>
              </v-card> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addAdditionalProduct" max-width="400px" persistent>
      <v-card>
        <v-card-title>Additional Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addAdditionalProduct = false"
            color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list dense>
          <v-list-item v-for="(product, index) in uniqueProducts.filter(
            (x) => x.variation === 'Standard'
          )" :key="index">
            <v-checkbox :label="product.product" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import ManageContainerMilestone from "./Dialogs/ManageContainerMilestone.vue";
import ScheduleModal from "./Dialogs/SailingSchedule.vue";
export default {
  props: ["bookingObj", "related", "relatedLoading"],
  components: {
    ManageContainerMilestone,
    ScheduleModal,
  },
  data: () => ({
    addAdditionalProduct: false,
    loadingCarriers: false,
    addMilestoneModal: false,
    allOriginPorts: [],
    allDestinationPorts: [],
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },


      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" }, 

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    bookingOfficePlaces: [],
    bookingOfficeLoading: false,
    bookingOfficeQuery: undefined,
    bookingOfficeTimeout: null,
    bookingOfficeSearch: null,
    bookingOfficeModal: false,
    booking: {
    },
    bookingMode: 0,
    bookingType: 0,
    caProductModal: false,
    caProducts: [
      {
        shippingLine:"HPL",
        name:"EXTRAFRESH PLUS",
        description:"EXTRAFRESH PLUS",
        oxygen:4,
        carbonDioxide:6,
      },
      {
        shippingLine: "MSK",
        name: "STARCARE",
        description: "STARCARE",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCOOL",
        description: "STARCOOL",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "LIVENTUS",
        description: "LIVENTUS",
        oxygen: 8,
        carbonDioxide: 15,
      },
      {
        shippingLine: "ONE",
        name: "MAXTEND",
        description: "MAXTEND",
        oxygen: 5,
        carbonDioxide: 10,
      },
      {
        shippingLine: "MSC",
        name: "XTENDFR A",
        description: "XTENDFRESH AVOCADOS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "HPL",
        name: "EXTRAFR B",
        description: "EXTRAFR BERRIES",
        oxygen: 4,
        carbonDioxide: 12,
      },
    ],
    cargoItem: {},
    cargoModal: false,
    chargeOptions: [
      { name: "Buyer", value: "buyer" },
      { name: "Forwarder", value: "forwarder" },
      { name: "Shipper", value: "shipper" },
      { name: "Consignee", value: "consignee" },
      { name: "1st Notify Party", value: "firstNotify" },
      { name: "2nd Notify Party", value: "secondNotify" },
      { name: "On Behalf of Shipper", value: "onBehalfShipper" },
      { name: "Customer", value: "customer" },
    ],
    containerItem: {},
    containerModal: false,
    containerItemModal: false,
    containerTypes: [],
    containerTypeKey: 1000,
    contractKey: 10000,
    contractModal: false,
    countries: [],
    countryModal: false,
    countryType: null,
    customerSearchModal: false,
    destinationPortKey: 6000,
    etdMenu: false,
    filterProductType: "Product",
    haulierModal: false,
    haulierKey: 7500,
    importExport: 0,
    incoTermModal: false,
    searchCustomer: null,
    incoTerms: [],
    linkedOrganisations: [],
    loadingLinkedOrganisations: false,
    loadingFunctions: false,
    loadingPointItem: {},
    loadingPointKey: 2000,
    loadingOriginPorts: false,
    loadingDestinationPorts: false,
    loadingPortSearch: false,
    loadingProduct: false,
    loadingSchedules: false,
    loadingShipmentProfiles: false,
    loadingTransporters: false,
    loadingWeighBridges: false,
    milestoneItem: {},
    milestoneKey: 3000,
    oceanScheduleQuery: null,
    oceanScheduleTimeout: null,
    originPortKey: 5000,
    partyModal: false,
    partyType: null,
    poiFunctions: [],
    poiModal: false,
    portResults: [],
    poiSearch: null,
    portSearch: null,
    portSearchQuery: undefined,
    portSearchTimeout: null,
    poiSearchLoading: false,
    poiSearchTimeout: null,
    poiRequest: null,
    containerDialog: false,
    selectedContainer: null,
    poiParams: {
      limit: 50,
      offset: 0,
      search: null,
    },
    savingContainerItem: false,
    pointsOfInterest: [],
    polModal: false,
    podModal: false,
    preadviseKey: 100,
    fdModal: false,
    selectedTransportModes:['Port'],
    transportModes:['Rail', 'Road', 'Port', 'Airport'], 
    paymentModal: false,
    paymentItem: {},
    paymentKey: 8000,
    paymentTypes: [
      { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
      { key: "PayableElsewhere", value: "Payable Elsewhere" },
    ],
    paymentTowns: [],
    paymentTownTimeout: undefined,
    paymentTownLoading: false,

    products: {
      data: [],
    },
    profileKey: 4500,
    regimeCodes: [],
    regimeCodeLoading: false,
    regimeCodeModal: false,
    regimeCodeSearch: null,
    searchProfiles: null,
    shippingLines: [],
    shippingLineModal: false,
    shipmentProfiles: {
      data: [],
    },
    temporaryProductsView: [
      { icon: "bolt", color: "#D6D46D", name: "Genset" },
      { icon: "watch_later", color: "#BB2525", name: "Late Arrival" },
      { icon: "router", color: "#18e00d", name: "TelemPlus" },
      { icon: "shopping_bag", color: "#F1DBBF", name: "Dunnage Bags" },
      { icon: "air", color: "#6A9C89", name: "Otflow" },
      { icon: "sanitizer", color: "#5b93c7", name: "Zoono" },
      { icon: "cleaning_services", color: "#BB2525", name: "Fumigation" },
      { icon: "power", color: "#D6D46D", name: "Void Plugs" },
      { icon: "ac_unit", color: "#13a7d4", name: "Blast Freezing" },
      { icon: "settings_overscan", color: "#EEF296", name: 'ContainerLiners' },
      { icon: "pivot_table_chart", color: "#508D69", name: "Latches" },
      { icon: "settings_input_component", color: "#EA906C", name: "Ratches" },
      {icon:'filter_alt',color:"#EE12CA",name:"Ethylene Filters"},
      { icon: "video_stable", color: "#5F6F52", name: 'Stabling bars' },
      { icon: "note", color: "#C5E898", name: "Kraft paper" },
      { icon: "backpack", color: "#DCBFFF", name: 'Absorb Bags' },
      { icon: "emergency", color: "#FFBFBF", name: 'SteriInsurance' },
      {icon:"smart_screen", color:"#FFBFFF", name:'SmartFresh'},
      {icon:"scale", color:"#000072", name:'HeavyLoad'}

      // { icon: "monitor_heart", color: "#F1DBBF", name: "Port Monitoring" },
    ],
    shipmentProfileDialog: false,
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    sailingScheduleModal: false,
    sailingScheduleError: false,
    savingBooking: false,
    schedules: [],
    scheduleKey: 7000,
    searchCountries: null,
    searchOrganisation: null,
    searchKey: 3500,
    searchOrigin: null,
    searchDestination: null,
    searchShippingLine: null,
    searchTowns: null,
    selectedCarriers: [],
    selectableParties: [],
    serviceType: 0,
    showSchedules: false,
    showShipmentLegs: [],
    shipmentProfileConfig: false,
    shipmentParams: {
      carriers: [],
      destination: null,
      destinationPort: null,
      includeNearbyDestinationPorts: false,
      includeNearbyOriginPorts: false,
      origin: null,
      originPort: null,
      searchDate: new Date().toISOString().substr(0, 10),
      searchDateType: "ByDepartureDate",
      services: "",
      scacs: "ANRM, ANNU, CMDU, SUDU, HLCU, MSCU, MAEU, DAL, ONEY, PABV",
      weeksOut: 2,
    },
    step: 1,
    suggestedDealTerms: [],
    transportItem: {},
    transporters: [],
    updateKey: 4000,
    ventSettings: [],
    weighBridges: [],
    weekSelection: [
      {
        value: 2,
        label: "2 Weeks",
      },
      {
        value: 4,
        label: "4 Weeks",
      },
      {
        value: 6,
        label: "6 Weeks",
      },
      {
        value: 8,
        label: "8 Weeks",
      },
    ],
    selectedQuantityType: { value: "", text: "" },
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    profileDetail: null,
    profileDetailKey: 975,
    //temporary products data
    addServiceModal: false,
    portMonitoringKey: 0,
    lateArrivalKey: 0,
    blastFreezingKey: 0,
    voidPlugKey: 0,
    otflowKey: 0,
    zoonoKey: 0,
    industrialKey: 0,
    gensetToggleKey: 0,
    temporaryProductModal: false,
    bulkType: null,
    saveProducts: false,
    availableDevices: [
      {
        product: "TelemPlus",
        variation: "2G GEO",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
      // {
      //   product: "TelemPlus",
      //   variation: "3G GEO",
      //   count: 0,
      //   monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "T4",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "RF",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "4G GEO",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      // {
      //   product: "TelemPlus",
      //   variation: "GEO EAGLE",
      //   count: 0,
      //   monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "MOST",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "2G TIVE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "5G TIVE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "Dunnage Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,

      },
      {
        product: "Blast Freezing",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Kraft paper",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Absorb Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Stabling bars",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Latches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ratches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ethylene Filters",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
      }
    ],
    pluggedInKey: 120,
    settingShipmentProfile: false,
    availableProducts: {
      Genset: false,
      Otflow: false,
      Zoono: false,
      // Dunnage_Bags: false,
      Void_Plugs: false,
      Late_Arrival: false,
      Early_Arrival:false,
      Fumigation: false,
      monitoringService: null,
      ContainerLiners: false,
      SteriInsurance: false,
      SmartFresh: false,
      HeavyLoad: false,
    },
    lateDescription: null,
    earlyDescription : null,
    searchContract: null,
    suggestedContracts: [],
    ventKey: 1050,
    regimeKey: 10500,
  }),
  watch: {
    'containerItem.pluggedInRequired': function (newVal) {
      if (newVal) {
        this.containerItem.transportStatus = 'Arrange Plugged In';
      }
      else {
        this.containerItem.transportStatus = 'Unallocated';
      }

    },
    'booking.regimeId': {
      immediate: true,
      async handler(val) {
        if (this.booking.regimeId) {
          let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId)
          if (ventSettings.length > 0) {
            this.ventSettings = [
              "5",
              "10",
              "15",
              "20",
              "25",
              "28",
              "30",
              "35",
              "40",
              "50",
              "Closed",

              "Fully Open",
              "CA",
              "MA",
              "Auto",
            ].filter(x => ventSettings.map(y => y.ventCode).includes(x))
          } else {
            this.ventSettings = [
              "5",
              "10",
              "15",
              "20",
              "25",
              "28",
              "30",
              "35",
              "40",
              "50",
              "Closed",

              "Fully Open",
              "CA",
              "MA",
              "Auto",
            ]
          }
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ]
        }
      }
    },
    async bookingOfficeSearch(val) {
      if (this.bookingOfficeLoading) {
        clearTimeout(this.bookingOfficeTimeout);
      }
      if (val && val.length > 2) {
        if (this.bookingOfficeLoading && this.bookingOfficeQuery) {
          this.bookingOfficeQuery.abort();
        }
        this.bookingOfficeLoading = true;
        let params = {
          search: val,
          // filter: {
          //     countryCode: this.booking.polCountry.iso2
          // },
        };
        if (this.booking.originCountry) {
          params.filter = {
            countryCode: this.booking.originCountry.iso2,
          };
        }

        this.bookingOfficeTimeout = setTimeout(async () => {
          this.bookingOfficeQuery = new AbortController();
          const signal = this.bookingOfficeQuery.signal;
          this.bookingOfficePlaces = await this.$API.searchTowns({
            params: params,
            signal,
          });
          this.bookingOfficeLoading = false;
        }, 750);
      } else {
        clearTimeout(this.bookingOfficeTimeout);
        this.isLoading = false;
      }
    },
    poiSearch: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchPois(val);
        }
      },
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: this.selectedTransportModes,
      };
      console.log('params.portType', params.portType)
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 750);
    },
    async searchFinalDestination(val) {
      if (this.loadingFinalDestination) {
        clearTimeout(this.finalDestinationPointTimeout);
      }
      if (this.loadingFinalDestination && this.finalDestinationQuery) {
        this.finalDestinationQuery.abort();
      }
      this.loadingFinalDestination = true;
      let params = {
        search: val,
      };
      if (this.transportItem.mode == "MaritimeTransport") {
        params.portType = "port";
      }
      this.finalDestinationPointTimeout = setTimeout(async () => {
        this.finalDestinationQuery = new AbortController();
        const signal = this.finalDestinationQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
        });
        this.loadingFinalDestination = false;
      }, 750);
    },
    searchTowns(val) {
      if (this.paymentTownLoading) {
        clearTimeout(this.paymentTownTimeout);
      }
      if (val && val.length > 2) {
        this.paymentTownLoading = true;
        this.paymentTownTimeout = setTimeout(async () => {
          this.paymentTowns = await this.$API.searchTowns({
            params: {
              search: val,
            },
          });
          this.paymentTownLoading = false;
        }, 250);
      } else {
        clearTimeout(this.paymentTownTimeout);
        this.paymentTownLoading = false;
      }
    },
    async searchVessel(val) {
      if (this.vesselLoading && this.vesselTimeout) {
        clearTimeout(this.vesselTimeout);
      }
      if (this.vesselLoading && this.vesselQuery) {
        this.vesselQuery.abort();
      }
      this.vesselLoading = true;
      this.vesselTimeout = setTimeout(async () => {
        this.vesselQuery = new AbortController();
        const signal = this.vesselQuery.signal;
        this.vessels = await this.$API.searchVessels({
          signal,
          params: {
            search: val,
          },
        });
        this.vesselLoading = false;
      }, 750);
    },
  },
  computed: {
    // isButtonDisabled() {
    //   return !this.booking.customerId ||
    //     !this.booking.etd ||
    //     (this.booking.movementType == 'OCEAN' && !this.booking.vesselId) ||
    //     (this.booking.bookingContainerItems && this.booking.bookingContainerItems.length > 0 &&
    //       this.booking.bookingContainerItems.some(container =>
    //         !container.transportCoordinator || !container.transportCoordinator.name));
    // },
    // disabledReason() {
    //   let reasons = [];
    //   if (!this.booking.customerId) reasons.push("Customer ID is missing");
    //   if (!this.booking.etd) reasons.push("ETD is missing");
    //   if (this.booking.movementType == 'OCEAN' && !this.booking.vesselId) reasons.push("Vessel ID is missing for ocean movement");
    //   if (this.booking.bookingContainerItems && this.booking.bookingContainerItems.length > 0 &&
    //     this.booking.bookingContainerItems.some(container => !container.transportCoordinator || !container.transportCoordinator.name)) {
    //     reasons.push("Some container items lack a transport coordinator");
    //   }
    //   console.log('reasons', reasons)
    //   return reasons.join(", ");
    // },
    hasProductsCount() {
      return Object.values(this.availableProducts).some((x) => x === true);
    },
    hasDevicesWithCount() {
      return this.availableDevices.some((device) => device.count > 0);
    },
    hasDevices() {
      let devices = this.availableDevices.filter((device) => device.count > 0);
      return devices;
    },
    hasProducts() {
      let finalProducts = [];
      let products = Object.keys(this.availableProducts).filter(
        (x) => this.availableProducts[x] === true
      );
      products = products.map((x) => x.replace(/_/g, " "));
      products.forEach((product) => {
        let productObject = {
          product: product,
          selected: true,
        };
        finalProducts.push(productObject);
      });
      return finalProducts;
    },
    uniqueProducts() {
      const unique = new Set();
      const result = [];
      for (const device of this.availableDevices) {
        if (!unique.has(device.product)) {
          unique.add(device);
          result.push(device);
        }
      }
      return result;
    },
    filterSuggestedContracts() {
      let result = this.suggestedContracts;
      if (this.searchContract) {
        result = result.filter(
          (x) =>
            (x.contracts[0] &&
              x.contracts[0].contractNo
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.name
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.alias
                .toLowerCase()
                .includes(this.searchContract.toLowerCase()))
        );
      }
      return result;
    },
    activeHaulier() {
      let hauliers = this.hauliers;
      // console.log({
      //     transporterId: this.booking.transporterId,
      //     isCarrierHaulage: this.booking.isCarrierHaulage,
      //     isPrivateHaulage: this.booking.isPrivateHaulage,
      //     isRailage: this.booking.isRailage
      // })
      if (this.booking.transportCoordinatorId) {
        return hauliers.find(
          (x) => x.id == this.booking.transportCoordinatorId
        );
      } else if (this.booking.isCarrierHaulage) {
        return hauliers.find((x) => x.flag == "isCarrierHaulage");
      } else if (this.booking.isPrivateHaulage) {
        return hauliers.find((x) => x.flag == "isPrivateHaulage");
      } else if (this.booking.isRailage) {
        return hauliers.find((x) => x.flag == "isRailage");
      } else return null;
    },
    availableParties() {
      let keys = ["Shipper", "Forwarder", "Consignee", "Notify Party"];
      //   let result = [];
      //   for (let i = 0; i < keys.length; i++) {
      //     if (this.booking[keys[i] + "Id"]) {
      //       result.push(keys[i]);
      //     }
      //   }
      //   console.log(result)
      return keys;
    },

    availableDealTerms() {
      let terms = [];
      if (this.booking.consigneeProfile) {
        for (
          let i = 0;
          i < this.booking.consigneeProfile.consigneeProfileIncoTerms.length;
          i++
        ) {
          let term = this.booking.consigneeProfile.consigneeProfileIncoTerms[i];
          let result = term.consigneeProfileShippingContracts
            .filter((x) => x.shippingLine)
            .map((y) => ({
              id: term.id,
              incoTerm: term.incoTerm,
              shippingLine: y.shippingLine,
              scacCode: y.shippingLine.scacCode,
              contractNumber: y.contractNumber,
              contractOwner: y.contractOwner,
            }));
          terms = [...terms, ...result];
        }
      }
      return terms;
    },
    // suggestedDealTerms() {
    //     let terms = this.availableDealTerms.filter(x => x.scacCode == this.booking.carrierScac)
    //     return terms
    // },
    displayReeferSettings() {
      let result = false;
      if (this.containerItem && this.containerItem.containerTypeCode) {
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        result = findType && findType.typeCategory == "Reefer";
      }
      return result;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterCustomerOrganisations() {
      let result = this.linkedOrganisations;
      if (this.booking.customer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.booking.customerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.booking.customer,
            relatedOrganisationId: this.booking.customerId,
          });
        }
      }
      // let uniqueOrganisations = [...new Set(result.map(x=>x.relatedOrganisationId))]
      // uniqueOrganisations = uniqueOrganisations.map(x=>result.find(y=>y.relatedOrganisationId == x))
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result.sort((a, b) =>
        a.relatedOrganisation.name > b.relatedOrganisation.name
          ? 1
          : b.relatedOrganisation.name > a.relatedOrganisation.name
            ? -1
            : 0
      );
      return result;
    },
    filterProducts() {
      let result = this.products.data.filter((x) => x.hsCode);
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) => x.status == "Approved"
      );
      if (this.searchProfiles) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
    filterOrganisations() {
      let result = this.related.data ?? [];
      result = result.filter((x) => x.isActive && x.isCustomer);
      let unique = [...new Set(result.map((x) => x.relatedOrganisationId))];
      unique = unique.map((x) =>
        result.find((y) => y.relatedOrganisationId == x)
      );
      let finalResult = [];
      for (let i = 0; i < unique.length; i++) {
        let find = result.find(
          (x) => x.relatedOrganisationId == unique[i].relatedOrganisationId
        );
        if (find) {
          finalResult.push(find);
        }
      }
      if (this.searchCustomer) {
        finalResult = finalResult.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }
      return finalResult;
    },
    filterDestinationPort() {
      let result = this.allDestinationPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase()) ||
            x.locode
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase())
        );
      }
      return result;
    },
    filterOriginPort() {
      let result = this.allOriginPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });

      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) ||
            x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase())
        );
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.shippingLines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase())) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
    formattedSchedules() {
      let data = this.schedules.map((x) => ({
        ...x,
        date: new Date(x.originDepartureDate),
        arrivalDate: new Date(x.destinationArrivalDate),
        sailingWeek: this.ISOWeek(new Date(x.originDepartureDate)),
      }));
      if (this.sortVoyage) {
        if (this.sortVoyage == "Earliest Departure") {
          data.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
        } else if (this.sortVoyage == "Earliest Arrival") {
          data.sort((a, b) =>
            a.arrivalDate > b.arrivalDate
              ? 1
              : b.arrivalDate > a.arrivalDate
                ? -1
                : 0
          );
        } else if (this.sortVoyage == "Duration") {
          data.sort((a, b) =>
            a.totalDuration > b.totalDuration
              ? 1
              : b.totalDuration > a.totalDuration
                ? -1
                : 0
          );
        }
      }
      let uniqueDates = [...new Set(data.map((x) => x.sailingWeek))];
      let result = [];
      for (let i = 0; i < uniqueDates.length; i++) {
        let uniqueVessels = [
          ...new Set(
            data
              .filter((x) => x.sailingWeek == uniqueDates[i])
              .map((y) => y.vesselName)
          ),
        ];
        let obj = {
          sailingWeek: uniqueDates[i],
          vessels: uniqueVessels.map((x) => ({ name: x })),
        };
        for (let j = 0; j < obj.vessels.length; j++) {
          obj.vessels[j].voyage = data.filter(
            (x) =>
              x.sailingWeek == uniqueDates[i] &&
              x.vesselName == obj.vessels[j].name
          )[0].voyageNumber;
          obj.vessels[j].schedules = data.filter(
            (x) =>
              x.sailingWeek == uniqueDates[i] &&
              x.vesselName == obj.vessels[j].name
          );
        }
        result.push(obj);
      }
      return result;
    },
    filteredVentSettings() {
      let result = [
        "Closed",
        "15",
        "20",
        "25",
        "28",
        "30",
        "35",
        "40",
        "50",
        "Fully Open",
        "CA",
        "MA",
        "Auto",
      ];
      // if (this.booking.regime && this.booking.regime.regimeVents) {
      //     result = this.booking.regime.regimeVents.map(x => x.ventCode)
      // }
      return result;
    },

    hauliers() {
      let result = [
        // {
        //     index: 1,
        //     type: 'Organisation',
        //     name: this.$store.state.currentOrg.name,
        //     logo: this.$store.state.currentOrg.logo,
        //     id: this.$store.state.currentOrg.id,
        //     icon: null,
        // },
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      // if (this.booking.shippingLine) {
      //     result[1].name = this.booking.shippingLine.name
      //     result[1].logo = this.booking.shippingLine.logo
      // }
      return result;
    },
    dischargePorts() {
      let result = this.booking.consigneeProfile
        ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        )
        : [];
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase())) ||
            (x.code &&
              x.code
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    loadingPorts() {
      let result = this.booking.consigneeProfile
        ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
          (x) => x.type == "loading"
        )
        : [];
      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name.toLowerCase().includes(this.searchOrigin.toLowerCase())) ||
            (x.code &&
              x.code.toLowerCase().includes(this.searchOrigin.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    validDetails() {
      let validateFields = [
        "portOfLoadValue",
        "portOfDischargeValue",
        "finalDestinationValue",
        "bookingOffice",
        "incoTerm",
        "regimeCode",
        "originCountry",
        "destinationCountry",
      ];
      let result = true;
      for (let i = 0; i < validateFields.length; i++) {
        if (!this.booking[validateFields[i]]) {
          result = false;
          break;
        }
      }
      if (
        !this.booking.bookingContainerItems ||
        this.booking.bookingContainerItems.length == 0
      ) {
        result = false;
      }
      return result;
    },
  },
  async created() {
    this.incoTerms = await this.$API.getIncoTerms();
    // this.containerTypes = await this.$API.listContainerTypes()
    this.getCarrierList();
    this.loadCountries();
    this.loadProducts();
    this.getRegimeCodes();
    this.getPoiFunctions();
    this.getTransporters();
    this.loadingWeighBridges = true;
    this.weighBridges = await this.$API.getPOIByFunction("Weigh-Bridge");
    this.loadingWeighBridges = false;
  },
  mounted() {
    // console.log(this.bookingObj)
    this.booking = { ...this.bookingObj };
    this.booking.onCarriage = true
  },
  methods: {
    removeImportShipperPart(){
      this.partyType = 'Shipper'
      this.booking.shipper = null
      this.booking.shipperId = null

    },
    checkItemCount(item) {
      let count = parseInt(item.count)
      if (count < 1) {
        item.checked = false
      }
    },
    updateProductCount(product) {
      //check the devices for the product and varaition
      let count = parseInt(product.count);
      this.availableDevices.forEach((x) => {
        if (x.product == product.name && x.variation == product.variation) {
          x.count = count;
        }
        if (x.count < 1) {
          x.checked = 0
        }
      });

    },
    generateKey(product) {
      return `${product.product}-${product.variation}`;
    },
    exitContainerItemModal() {
      (this.availableDevices = [
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "Escavox",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Void Plugs",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ethylene Filters",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
        }
      ]),
        (this.availableProducts = {
          Genset: false,
          Otflow: false,
          Zoono: false,
          // Dunnage_Bags: false,
          Void_Plugs: false,
          Late_Arrival: false,
          Early_Arrival: false,
          Fumigation: false,
          monitoringService: null,
          ContainerLiners: false,
          HeavyLoad: false,
          SteriInsurance: false,

        }),
        (this.containerItemModal = false);
      this.lateDescription = null;
      this.earlyDescription= null;
      this.containerItem = {};
    },
    closeTemporaryProductModal() {
      // this.availableDevices = [
      //   { product: "TelemPlus", variation: "2G GEO", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "3G GEO", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "USB", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "RF", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "4G GEO", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "GEO EAGLE", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "MOST", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "2G TIVE", count: 0, monitoringService: '' },
      //     { product: "TelemPlus", variation: "5G TIVE", count: 0, monitoringService: '' },
      //     { product: "Void Plugs", variation: "Standard", count: 0, monitoringService: '' },
      //     { product: "Blast Freezing", variation: "Standard", count: 0, monitoringService: '' },
      //   ],
      //   this.availableProducts = {
      //   'Genset': false,
      //   'Otflow': false,
      //   'Dunnage_Bags': false,
      //   'Late_Arrival': false,
      //   'Fumigation': false,
      //   'monitoringService': null,

      // }
      this.temporaryProductModal = false;
    },
    addProducts() {
      this.saveProducts = true;
      let monitoringService = this.availableProducts.monitoringService;
      let temporaryProducts = [];
      let filteredDevices = this.availableDevices.filter((x) => x.count > 0);

      filteredDevices.map((x) => {
        // x.monitoringService = monitoringService
        // x.checked=null
        x.monitoringService = monitoringService;
        x.count = parseInt(x.count);
        delete x.checked;
        // const newObj={...x}
        // delete newObj.checked
        // return newObj
      });
      let filteredProducts = Object.keys(this.availableProducts).filter(
        (x) => this.availableProducts[x] == true
      );
      filteredProducts.map((x) => {
        let product = {
          product: x,
          variation: "Standard",
          count: 1,
          monitoringService: monitoringService,
        };
        temporaryProducts.push(product);
      });
      console.log("filteredDevices", filteredDevices);
      temporaryProducts = [...temporaryProducts, ...filteredDevices];
      this.containerItem.temporaryProducts = temporaryProducts;

      this.saveProducts = false;
      this.temporaryProductModal = false;
    },
    toggleCheckbox(product) {
      product.count = product.count === 0 ? 1 : 0;
    },
    toggleProducts(choice, type) {
      // console.log("chouice", choice);
      // console.log("type", type);
      // console.log("this.availableProducts start", this.availableProducts);

      //change all spaces in my string to underscores
      type = type.replace(/ /g, "_");
      if (choice === "YES") {
        if (
          !Object.hasOwn(this.availableProducts, type) ||
          this.availableProducts[type] === false
        ) {
          this.availableProducts[type] = true;
        } else {
          delete this.availableProducts[type];
        }
        this.gensetToggleKey++;
        this.otflowKey++;
        this.zoonoKey++;
        this.industrialKey++;
        this.portMonitoringKey++;
        this.voidPlugKey++;
        this.lateArrivalKey++;
      }
      if (choice === "NO") {
        // console.log('inside no')
        if (
          !Object.hasOwn(this.availableProducts, type) ||
          this.availableProducts[type] === true
        ) {
          this.availableProducts[type] = false;
        } else {
          delete this.availableProducts[type];
        }
        // console.log('this.availableProducts end', this.availableProducts)
        this.gensetToggleKey++;
        this.otflowKey++;
        this.zoonoKey++;
        this.industrialKey++;
        this.portMonitoringKey++;
        this.voidPlugKey++;
        this.lateArrivalKey++;
      }
    },
    async getTemporaryProducts() {
      // if(type=='Add'){
      //   // this.bulkType = 'Add'
      //   this.temporaryProductModal = true
      // }
      // else if (type=='Edit'){
      //   this.temporaryProductModal = true
      // }
      this.temporaryProductModal = true;
    },
    addServices() {
      this.addServiceModal = true;
    },
    mapTemporaryProducts(temporaryProduct) {
      console.log(temporaryProduct)
      if(temporaryProduct && Array.isArray(temporaryProduct)){
        let devicesList = 'Devices Required:\n'
      return temporaryProduct.reduce((acc, product) => {
        const existingProduct = acc.find(
          (accProduct) => accProduct.product === product.product
        );
        if (!existingProduct) {
          const view = this.temporaryProductsView.find(
            (view) => view.name === product.product
          );
          if (view) {
            if (view.name == 'TelemPlus') {
              devicesList += '• ' + product.count + 'X' + product.variation + '\n'
              // const existingDevice = acc.find(
              //   (accProduct) => accProduct.product === 'Devices Required'
              // );
              // if(!existingDevice){
              // acc.push({
              //   product: 'Devices Required',
              //   variation: product.variation,
              //   icon: view.icon,
              //   color: view.color,
              // })
              // }
            }
            else if (view.name == 'Late Arrival') {
              acc.push({
                product: product.product + ' ' + 'Required' + ' : ' + product.variation,
                icon: view.icon,
                color: view.color,
              })
            }

            else if (view.name == 'Early Arrival') {
              acc.push({
                product: product.product + ' ' + 'Required' + ' : ' + product.variation,
                icon: view.icon,
                color: view.color,
              })
            }
            else if (view.name === 'SteriInsurance') {
              acc.push({
                product: 'Steri Insurance' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else if (view.name === 'SmartFresh') {
              acc.push({
                product: 'Smart Fresh' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else if (view.name === 'ContainerLiners') {
              acc.push({
                product: 'Container Liners' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else {
              acc.push({
                product: product.product + ' ' + 'Required',
                icon: view.icon,
                color: view.color,
              });
            }
          }

        }

        if (product.product === 'Monitoring Service' && product.variation) {

          acc.push({
            product: product.variation + ' ' + 'Service',
            variation: product.monitoringService,
            icon: "desktop_windows",
            color: "#99B080"
          })

        }
        if (devicesList.length > 17) {
          //inside my acc check if there is entry with varaition none
          const existingDevice = acc.find(
            (accProduct) => accProduct.variation == 'None')
          if (!existingDevice) {
            acc.push({
              product: devicesList,
              variation: 'None',
              icon: "router",
              color: "green",
            })
          }
          else {
            //remove the existing entry and replace with new one
            acc.splice(acc.indexOf(existingDevice), 1)
            acc.push({
              product: devicesList,
              variation: 'None',
              icon: "router",
              color: "green",
            })
          }
        }
        return acc;
      }, []);
      } else return []
      
    },
    getClientStatusColor(status) {
      switch (status) {
        case 'GOOD STANDING':
          return 'green'
        case 'INACTIVE':
          return 'red'
        case 'ON HOLD':
          return 'orange'
        case 'COD':
          return 'blue'
      }
    },
    selectQuantityType() {
      this.cargoItem.quantityTypeCode = this.selectedQuantityType.value;
      this.cargoItem.quantityType = this.selectedQuantityType.text;
    },
    addContainerCargoDetails() {
      this.cargoItem = {
        type: "container",
        index: this.containerItem.bookingCargoItems.length,
        quantityType: "Carton",
        quantityTypeCode: "CT",
      };
      let findContainer = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      if (findContainer) {
        this.cargoItem.volume = 25 * findContainer.teu;
      }

      this.cargoModal = true;
    },
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: this.booking.portOfLoadValue,
          autosave,
          isActual: false,
        };
      }

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    addPayment() {
      if (!this.booking.bookingPaymentDetails) {
        this.booking.bookingPaymentDetails = [];
      }
      this.paymentItem = {
        index: this.booking.bookingPaymentDetails.length,
      };
      this.paymentModal = true;
    },
    async addContainerDetails(type = null) {
      this.bulkType = "Add";
      if (this.booking.serviceType == "BREAKBULK") {
        this.containerItem = {
          quantity: 1,
          bookingCargoItems: [],
          type: "BREAKBULK",
          index: this.booking.bookingContainerItems.length,
          consignee: this.booking.consigneeId ? this.linkedOrganisations.find(x => x.relatedOrganisationId == this.booking.consigneeId)?.relatedOrganisation : null,
          consigneeId: this.booking.consigneeId,
          buyer: this.booking.buyerId ? this.linkedOrganisations.find(x => x.relatedOrganisationId == this.booking.buyerId)?.relatedOrganisation : null,
          buyerId: this.booking.buyerId,
          verificationMethod:
            this.booking.movementType == "EXPORT" ? "Method 2" : null,
        };
        if (!this.containerItem.buyer) {
          this.containerItem.buyerId = null
        }
        if (!this.containerItem.consignee) {
          this.containerItem.consigneeId = null
        }
        this.containerItemModal = true;
      } else {
        if (!this.booking.bookingContainerItems) {
          this.booking.bookingContainerItems = [];
        }

        this.containerItem = {
          type: "CONTAINER",
          quantity: 1,
          bookingCargoItems: [],
          containerTypeCode: "45R1",
          containerType:"40 RF HC (45R1)",
          index: this.booking.bookingContainerItems.length,
          consignee: this.booking.consigneeId ? this.linkedOrganisations.find(x => x.relatedOrganisationId == this.booking.consigneeId)?.relatedOrganisation : null,
          consigneeId: this.booking.consigneeId,
          buyer: this.booking.buyerId ? this.linkedOrganisations.find(x => x.relatedOrganisationId == this.booking.buyerId?.relatedOrganisation) : null,
          buyerId: this.booking.buyerId,
          verificationMethod:
            this.booking.movementType == "EXPORT" ? "Method 2" : null,
        };
        if (!this.containerItem.buyer) {
          this.containerItem.buyerId = null
        }
        if (!this.containerItem.consignee) {
          this.containerItem.consigneeId = null
        }
        if (this.booking.vents) {
          this.containerItem.vents = this.booking.vents
        }
        else if (this.ventSettings.length == 1) {
          this.containerItem.vents = this.ventSettings[0]
        }
        if (this.booking.regime) {
          this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        }
        if (this.booking.caProduct) {
          this.containerItem.caProduct = this.booking.caProduct;
          this.containerItem.oxygen = this.booking.oxygen
          this.containerItem.carbonDioxide = this.booking.carbonDioxide
          this.containerItem.nitrogen = 0
        }

        // if (
        //   this.booking.consigneeProfile &&
        //   this.booking.consigneeProfile.consigneeProfileProducts
        // ) {
        //   for (
        //     let i = 0;
        //     i < this.booking.consigneeProfile.consigneeProfileProducts.length;
        //     i++
        //   ) {
        //     this.cargoItem =
        //       this.booking.consigneeProfile.consigneeProfileProducts[i];
        //     this.cargoItem.type = "container";
        //     this.cargoItem.index = i;
        //     delete this.cargoItem.id;
        //     this.setCargoItemDetails();
        //     await this.saveCargoItem();
        //   }
        // }
        // this.ventSettings = [
        //   "Closed",
        //   "15",
        //   "20",
        //   "25",
        //   "28",
        //   "30",
        //   "35",
        //   "40",
        //   "50",
        //   "Fully Open",
        //   "CA",
        //   "MA",
        //   "Auto",
        // ];
        this.containerItemModal = true;
      }
    },
    addLoadingPoint(type) {
      switch (type) {
        case "emptyLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (item.name == "Container Depot") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        case "firstLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (
              item.name == "Commercial Cold Store" ||
              item.name == "Pack-House (Off-Farm)" ||
              item.name == "Pack-House (On-Farm)"
            ) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        case "secondLoadingPoint":
          this.poiFunctions.forEach((item) => {
            if (
              item.name == "Commercial Cold Store" ||
              item.name == "Pack-House (Off-Farm)" ||
              item.name == "Pack-House (On-Farm)"
            ) {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        case "dropOffPoint":
          this.poiFunctions.forEach((item) => {
            if (item.name == "Port" || item.name == "Port Terminal") {
              item.selected = true;
            } else {
              item.selected = false;
            }
          });
          break;
        default:
          break;
      }
      this.loadingPointItem = {
        type: type,
      };
      this.poiModal = true;
    },
    constructAddressLine(addressLine, address) {
      switch (addressLine) {
        case 1:
          return [address.addressLine1, address.addressLine2]
            .filter(Boolean)
            .join(", ");
        case 2:
          return [address.addressLine3, address.addressLine4]
            .filter(Boolean)
            .join(", ");
        case 3:
          return [
            address.town,
            address.subdivision,
            address.country,
            address.postalCode,
          ]
            .filter(Boolean)
            .join(", ");
      }
    },
    concatenateAddress(address) {
      let result = [
        this.constructAddressLine(1, address),
        this.constructAddressLine(2, address),
        this.constructAddressLine(3, address),
      ];
      return result.filter(Boolean).join(", ");
    },
    deleteCargoItem(index) {
      this.containerItem.bookingCargoItems.splice(index, 1);
    },
    deleteContainerItem(index) {
      this.booking.bookingContainerItems.splice(index, 1);
      this.booking.isHazardous = this.booking.bookingContainerItems.some((x) =>
        x.bookingCargoItems.some((y) => y.hazardous == true)
      );
      this.containerItemModal = false;
    },
    deletePaymentItem(item) {
      this.booking.bookingPaymentDetails.splice(item.index, 1);
    },
    async editContractNumber() {
      let containers = this.booking.bookingContainerItems;
      let products = [
        ...new Set(
          [].concat
            .apply(
              [],
              containers.map((x) => x.bookingCargoItems)
            )
            .map((x) => x.productId)
        ),
      ];
      let containerTypes = [
        ...new Set(
          [].concat.apply(
            [],
            this.booking.bookingContainerItems.map((x) => x.containerTypeCode)
          )
        ),
      ];
      this.suggestedContracts = [];
      if (
        this.booking.etd &&
        containerTypes.length > 0 &&
        products.length > 0 &&
        this.booking.portOfLoadValue &&
        this.booking.portOfDischargeValue &&
        this.booking.shippingLineId
      ) {
        let contracts = await this.$API.getBookingContracts({
          params: {
            origin: this.booking.portOfLoadValue,
            originCountry: this.booking.portOfLoadValue
              ? this.booking.portOfLoadValue.substring(0, 2)
              : null,
            destination: this.booking.portOfDischargeValue,
            destinationCountry: this.booking.portOfDischargeValue
              ? this.booking.portOfDischargeValue.substring(0, 2)
              : null,
            contractPartyId: this.booking.shippingLineId,
            date: this.booking.etd,
            containerTypes: containerTypes,
            products: products,
          },
        });
        this.suggestedContracts = contracts;
      }

      this.contractModal = true;
    },
    editCargoItem(item) {
      this.cargoItem = item;
      this.cargoModal = true;
    },
    editContainerItem(item, index, type = null) {
      this.containerItem = item;
      this.bulkType = "Edit";
      if (this.containerItem.temporaryProducts) {
        let filteredDevices = this.containerItem.temporaryProducts.filter(
          (x) =>
            x.product == "TelemPlus" ||
            x.variation == 'Standard'
        );
        this.availableDevices.forEach((x) => {
          filteredDevices.forEach((y) => {
            if (x.product == y.product && x.variation == y.variation) {
              (x.count = y.count),
                (x.monitoringService = y.monitoringService),
                (x.checked = true);
            }
          });
        });
        let filteredProducts = this.containerItem.temporaryProducts.filter(
          (x) =>
            x.product != "TelemPlus" &&
            x.product != "Void Plugs" &&
            x.product != "Blast Freezing"
        );
        Object.keys(this.availableProducts).forEach((x) => {
          filteredProducts.forEach((y) => {
            if (x == y.product) {
              this.availableProducts[x] = true;
            }
          });
        });
      }
      if (this.availableProducts.Late_Arrival == true) {
        this.lateDescription = this.containerItem.temporaryProducts.find(
          (x) => x.product == "Late_Arrival"
        ).variation;
      }

      if (this.availableProducts.Early_Arrival == true) {
        this.earlyDescription = this.containerItem.temporaryProducts.find(
          (x) => x.product == "Early_Arrival"
        ).variation;
      }
      // const telemPlusDevice = this.availableDevices.find(
      //   (device) => device.count > 0 && device.product === "TelemPlus"
      // );
      // console.log("telemPlusDevice", telemPlusDevice);
      // if (telemPlusDevice) {
      //   this.availableProducts.monitoringService =
      //     telemPlusDevice.monitoringService;
      // }
      let findMonitoringService = this.containerItem.temporaryProducts.find(
        (x) => x.product === 'Monitoring Service'
      );
      console.log('containerItem', this.containerItem.temporaryProducts)
      if (findMonitoringService) {
        this.availableProducts.monitoringService = findMonitoringService.variation
      }
      if (item.containerMilestones && item.containerMilestones.length > 0) {
        for (let milestone of item.containerMilestones) {
          this.containerItem[milestone.type] = milestone;
        }
      }

      if (this.ventSettings.length == 0) {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",

          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
      this.containerItemModal = true;
    },
    editPaymentItem(item) {
      this.paymentItem = item;
      this.paymentModal = true;
    },
    async favouritePort(item, type, arrayType) {
      if (arrayType == "Origin") {
        let index = this.allOriginPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      } else {
        let index = this.allDestinationPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      }
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      );
    },
    findChargeType(type) {
      return this.paymentTypes.find((x) => x.key == type).value;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += ' ' + time
        }
      }
      return result;
    },
    formatParty(party) {
      switch (party) {
        case "forwarder":
          return "Forwarder";
        case "buyer":
          return "Buyer";
        case "consignee":
          return "Consignee";
        case "firstNotify":
          return "Notify Party";
        case "secondNotify":
          return "Notify Party";
        default:
          return party;
      }
    },
    getContractParty(party) {
      if (this.booking[party]) {
        return this.booking[party].name;
      } else return null;
    },
    async getRegimeCodeSettings() {
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId)
        if (ventSettings.length > 0) {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter(x => ventSettings.map(y => y.ventCode).includes(x))
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ]
        }
      } else {
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ]
      }
      if (!this.booking.vents) {
        this.booking.vents = this.ventSettings[0]
        this.ventKey++
      }

    },
    async getTransporters() {
      this.loadingTransporters = true;
      let result = await this.$API.getContractors();
      if (result) {
        this.transporters = result;
        this.loadingTransporters = false;
      }
    },
    async getPoiFunctions() {
      this.poiFunctions = await this.$API.getPoiFunctions();
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.booking.customerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    async getCarrierList() {
      this.loadingCarriers = true;
      this.shippingLines = await this.$API.getShippingLines();
      this.selectedCarriers = this.shippingLines
        .filter((x) => x.scacCode)
        .map((x) => x.scacCode);
      this.containerTypes = await this.$API.listContainerTypes();
      this.loadingCarriers = false;
    },
    getContainerDescription(code) {
      let find = this.containerTypes.find((x) => x.typeCode == code);
      return find ? find.shortDescription : null;
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.booking.destinationCountry) {
        params.countryCode = this.booking.destinationCountry.iso2;
      }
      if (
        this.containerItem &&
        this.containerItem.bookingCargoItems &&
        this.containerItem.bookingCargoItems.length > 0
      ) {
        params.productCode = this.containerItem.bookingCargoItems.map(
          (x) => x.productId
        );
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case "Draft":
          return "orange";
        case "Submitted":
          return "blue";
        case "Approved":
          return "green";
        case "Rejected":
          return "red";
        default:
          return;
      }
    },
    ISOWeek(dt) {
      var tdt = new Date(dt.valueOf());
      var dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      var firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
      if (
        !this.booking.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.booking.originCountry = find;
          this.booking.originCountryId = find.id;
        }
      }
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },
    openSailingSchedules() {
      if (this.booking.portOfLoadValue && this.booking.portOfDischargeValue) {
        this.sailingScheduleModal = true;
        // if(this.booking.consigneeProfile)
        // this.searchOceanSchedules()
      } else {
        this.sailingScheduleError = true;
      }
    },
    removeProfile() {
      this.booking.consigneeProfile = null;
      this.booking.consigneeProfileId = null;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.profileKey++;
    },
    async saveBooking() {
      try {
        if (!this.validDetails) {
          this.booking.state = "Draft";
        } else {
          this.booking.state = "Pending";
        }
        this.savingBooking = true;
        if (this.booking.caProduct) {
          // let findProduct = this.caProducts.find(
          //   (x) => x.name == this.booking.caProduct
          // );
          // if (findProduct) {
          //   this.booking.bookingContainerItems.forEach((x) => {
          //     x.caProduct = findProduct.name;
          //     x.oxygen = findProduct.oxygen;
          //     x.carbonDioxide = findProduct.carbonDioxide;
          //     x.nitrogen = 0
          //   });
          // }
          this.booking.bookingContainerItems.forEach((x) => {
              x.caProduct = this.booking.caProduct;
              x.oxygen = this.booking.oxygen;
              x.carbonDioxide = this.booking.carbonDioxide;
              x.nitrogen = 0
            });
        }
        // if(this.booking.transporterId){
        //     this.booking.bookingContainerItems.forEach(x => {
        //         x.transporterId = this.booking.transporterId
        //     })
        // }
        // this.booking.bookingOffice = this.chosenBookingOffice
        this.booking.numContainers = this.booking.bookingContainerItems.reduce(
          (a, b) => a + b.quantity,
          0
        );
        console.log(JSON.parse(JSON.stringify(this.booking)))
        let result = await this.$API.createBooking(this.booking);
        if (!this.booking.id) {
          this.$message.success("Successfully created booking!");

          this.$router.push({ path: "/booking/" + result.systemReference });
          this.savingBooking = false;
        } else {
          this.booking.id = result.id;
          this.savingBooking = false;
          this.$message.success("Successfully updated booking!");
        }
      } catch (e) {
        this.savingBooking = false;
        this.$message.error(e.message);
      }
    },
    saveCargoItem() {
      console.log(this.booking.regime);
      if (this.booking.regime) {
        this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        if (
          this.booking.regime.regimeVents &&
          this.booking.regime.regimeVents.length > 0
        ) {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) =>
            this.booking.regime.regimeVents.map((x) => x.ventCode).includes(x)
          );
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
        if (this.ventSettings.length == 1) {
          this.containerItem.vents = this.ventSettings[0];
        }
      }
      if (this.cargoItem.type == "container") {
        let find = this.containerItem.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;

        if (find > -1) {
          this.containerItem.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.containerItem.bookingCargoItems.push(this.cargoItem);
        }
      } else {
        let find = this.booking.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
        if (find > -1) {
          this.booking.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.booking.bookingCargoItems.push(this.cargoItem);
        }
      }
      this.cargoKey++;
      this.cargoModal = false;
      this.cargoItem = {};
    },
    saveContainerItem() {
      this.savingContainerItem = true;
      let temporaryProducts = [];
      // Object.keys(this.availableProducts).forEach((x) => {
      //   // console.log('x',x)
      //   if (this.availableProducts[x] && x == "Late_Arrival") {
      //     temporaryProducts.push({
      //       product: x,
      //       variation: this.lateDescription,
      //       count: 1,
      //       monitoringService: "",
      //     });
      //   } else if (this.availableProducts[x] && x != "monitoringService") {
      //     temporaryProducts.push({
      //       product: x,
      //       variation: "Standard",
      //       count: 1,
      //       monitoringService: "",
      //     });
      //   } else if (x == "monitoringService") {
      //     temporaryProducts.push({
      //       product: "Port Monitoring",
      //       variation: "Standard",
      //       count: 1,
      //       monitoringService: "",
      //     });
      //   }
      // });
      Object.keys(this.availableProducts).forEach((key) => {
        if (this.availableProducts[key]) {
          switch (key) {
            case "Late_Arrival":
              temporaryProducts.push({
                product: key,
                variation: this.lateDescription,
                count: 1,
                monitoringService: "",
              });
              break;

              case "Early_Arrival":
              temporaryProducts.push({
                product: key,
                variation: this.earlyDescription,
                count: 1,
                monitoringService: "",
              });
              break;

            case "monitoringService":
              temporaryProducts.push({
                product: "Monitoring Service",
                variation: this.availableProducts[key],
                count: 1,
                monitoringService: "",
              });

              break;

            default:
              temporaryProducts.push({
                product: key,
                variation: "Standard",
                count: 1,
                monitoringService: "",
              });
              break;
          }
        }
      });

      this.availableDevices.forEach((x) => {
        if (x.count > 0) {
          temporaryProducts.push({
            product: x.product,
            variation: x.variation,
            count: x.count,
            monitoringService: x.monitoringService,
          });
        }
      });
      // console.log('this.containerItem',this.containerItem)
      this.containerItem.temporaryProducts = temporaryProducts;

      if (this.containerItem.verificationMethod == "Method 2") {
        this.containerItem.weighBridgeId = null;
        this.containerItem.verificationMethodOwner = null;
      }
      let find = this.booking.bookingContainerItems.findIndex(
        (x) => x.index === this.containerItem.index
      );
      let findType = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      this.containerItem.containerTypeDescription = findType
        ? findType.shortDescription
        : null;
      // if(this.booking.destinationTerminal)
      if (find > -1) {
        this.booking.bookingContainerItems[find] = this.containerItem;
      } else {
        this.booking.bookingContainerItems.push(this.containerItem);
      }
      // this.containerKey++;
      (this.availableDevices = [
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Void Plugs",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "Escavox",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product:'Ethylene Filters',
          variation:'Standard',
          count:0,
          checked:false
          
        }
      ]),
        (this.availableProducts = {
          Genset: false,
          Otflow: false,
          Zoono: false,
          // Dunnage_Bags: false,
          Void_Plugs: false,
          Late_Arrival: false,
          Early_Arrival:false,
          Fumigation: false,
          monitoringService: null,
          ContainerLiners: false,
          SteriInsurance: false,
          SmartFresh: false,
          HeavyLoad: false,

        }),
        (this.containerItemModal = false);
      this.containerItem = {};
      this.savingContainerItem = false;
      this.lateDescription = null;
      this.earlyDescription = null;
    },
    savePaymentItem() {
      let find = this.booking.bookingPaymentDetails.findIndex(
        (x) => x.index === this.paymentItem.index
      );
      if (find > -1) {
        this.booking.bookingPaymentDetails[find] = this.paymentItem;
      } else {
        this.booking.bookingPaymentDetails.push(this.paymentItem);
      }
      this.paymentKey++;
      this.paymentModal = false;
      this.paymentItem = {};
    },
    async searchPois(val) {
      if (this.poiSearchLoading) {
        clearTimeout(this.poiSearchTimeout);
      }
      if (this.poiSearchLoading && this.poiRequest) {
        this.poiRequest.abort();
      }

      this.poiParams.filter = this.poiFunctions
        .filter((item) => item.selected)
        .map((item) => item.name);
      this.poiParams.search = val;
      if (val && val.length > 1) {
        this.poiSearchLoading = true;
        this.poiSearchTimeout = setTimeout(async () => {
          this.poiRequest = new AbortController();
          const signal = this.poiRequest.signal;
          this.pointsOfInterest = await this.$API.getPois({
            params: this.poiParams,
            signal,
          });
          this.poiSearchLoading = false;
        }, 750);
      } else {
        clearTimeout(this.poiSearchTimeout);
        this.poiSearchLoading = false;
      }
    },
    selectCarrier(scac) {
      if (this.selectedCarriers.includes(scac)) {
        let index = this.selectedCarriers.findIndex((x) => x == scac);
        this.selectedCarriers.splice(index, 1);
      } else {
        this.selectedCarriers.push(scac);
      }
    },
    async searchOceanSchedules() {
      if (this.oceanScheduleTimeout) {
        clearTimeout(this.oceanScheduleTimeout);
      }
      if (this.loading && this.oceanScheduleQuery) {
        this.oceanScheduleQuery.abort();
      }
      this.loadingSchedules = true;
      this.shipmentParams = {
        ...this.shipmentParams,
        // searchDate: new Date(),
        originPort: this.booking.portOfLoadValue,
        destinationPort: this.booking.finalDestinationValue,
      };
      // params.searchDate = this.transportItem.etd;
      // params.originPort = this.booking.portOfLoadValue;
      // params.destinationPort =
      //     this.booking.portOfDischargeValue;
      this.shipmentParams.scacs = this.selectedCarriers.join(", ");
      this.oceanScheduleTimeout = setTimeout(async () => {
        try {
          this.oceanScheduleQuery = new AbortController();
          const signal = this.oceanScheduleQuery.signal;
          this.schedules = await this.$API.getOceanSchedules({
            params: this.shipmentParams,
            signal,
          });
          this.schedules.sort((a, b) =>
            a.originDepartureDate > b.originDepartureDate
              ? 1
              : b.originDepartureDate > a.originDepartureDate
                ? -1
                : 0
          );
          this.loadingSchedules = false;
          this.showSchedules = true;
          this.scheduleKey++;
        } catch (error) {
          console.log(error);
          this.$message.error("An error occured while searching for schedules");
          this.loadingSchedules = false;
        }
      }, 750);
    },
    setCASettings() {
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = []
      }
      this.booking.bookingContainerItems.forEach((x) => {
        x.oxygen = this.booking.oxygen
        x.carbonDioxide = this.booking.carbonDioxide
      });
    },
    async setCAProduct() {
      this.booking.vents =
        this.booking.caProduct && this.booking.caProduct != "MAXTEND"
          ? "Closed"
          : this.booking.caProduct == 'MAXTEND' ? 'MAXTEND' : this.booking.vents;
      if (this.booking.caProduct) {
        let findProduct = this.caProducts.find(
          (x) => x.name == this.booking.caProduct
        );
        this.booking.oxygen = findProduct.oxygen;
        this.booking.carbonDioxide = findProduct.carbonDioxide;
      } else {
        this.booking.oxygen = null;
        this.booking.carbonDioxide = null;
      }
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = []
      }
      this.booking.bookingContainerItems.forEach((x) => {
        if (this.booking.caProduct) {
          if (this.booking.caProduct != "MAXTEND") {
            x.vents = "Closed";
          }
          x.caProduct = this.booking.caProduct;
          x.controlledAtmosphere = true;
          let findProduct = this.caProducts.find(
            (y) => y.name == this.booking.caProduct
          );
          if (findProduct) {
            x.oxygen = findProduct.oxygen;
            x.carbonDioxide = findProduct.carbonDioxide;
            x.nitrogen = 0;
          }
        } else {
          x.caProduct = null;
          x.controlledAtmosphere = false;
        }
      });
      this.caProductModal = false;
    },
    setMilestonePoint(item) {
      if (item.type) {
        this.containerItem[`${item.type}`] = item;
      }
      if (!this.containerItem.containerMilestones) {
        this.containerItem.containerMilestones = [];
      }
      let find = this.containerItem.containerMilestones.findIndex(
        (x) => x.type == item.type
      );
      if (find == -1) {
        this.containerItem.containerMilestones.push(item);
      } else {
        this.containerItem.containerMilestones[find] = item;
      }
      this.addMilestoneModal = false;
    },
    setBookingOffice(office) {
      this.booking.bookingOffice = office.locode;
      this.booking.bookingOfficeName = office.name;
      this.bookingOfficeModal = false;
    },
    setCADetails() {
      if (this.containerItem.caProduct) {
        let find = this.caProducts.find(
          (x) => x.name == this.containerItem.caProduct
        );
        if (find) {
          this.containerItem.oxygen = find.oxygen;
          this.containerItem.carbonDioxide = find.carbonDioxide;
          this.containerItem.nitrogen = 0
        }
      }
    },
    setCargoItemDetails() {
      delete this.cargoItem.id
      if (this.cargoItem.product) {
        this.cargoItem.description = this.cargoItem.product.hsCode.explanation;
        this.cargoItem.hsCode =
          this.cargoItem.product.hsCode.alternateCode.replace(/ /g, "");
          this.cargoItem.productId = this.cargoItem.product.id;
      }
    },
    // async setContract(contract) {
    //   this.booking.contractOwner = contract.contractOwner;
    //   this.booking.contractNumber = contract.contractNumber;
    //   this.contractModal = false;
    // },
    async setContract(contract) {
      let parties = [
        "buyer",
        "forwarder",
        "onBehalfForwarder",
        "shipper",
        "onBehalfShipper",
        "onBehalfForwarder",
        "consignee",
        "firstNotify",
        "secondNotify",
        "customer",
        "contractParty",
      ];
      let result = [];
      for (let party of parties) {
        if (this.booking[party]) {
          let obj = {
            role: party,
            ...this.booking[party],
          };
          result.push(obj);
        }
      }
      let owner = result.find((x) => x.id == contract.contractOwnerId);
      this.booking.contractId = contract.id;
      this.booking.contractOwner = owner ? owner.role : null;
      this.booking.contractNumber = contract.contracts[0].contractNo;
      this.booking.contractPartyId = contract.contractOwnerId
      this.contractModal = false;
    },
    async setCustomer(organisation) {
      this.booking.customer = organisation.relatedOrganisation;
      this.booking.customerId = organisation.relatedOrganisationId;
      this.booking.consigneeProfile = null;
      this.booking.consigneeProfileId = null;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.booking.incoTerm = null;
      // this.booking.portOfLoadValue = null;
      // this.booking.portOfLoadCity = null;
      // this.booking.portOfDischargeValue = null;
      // this.booking.portOfDischargeCity = null;
      // this.booking.finalDestinationValue = null;
      // this.booking.finalDestinationCity = null;
      // this.booking.originCountry = null;
      // this.booking.originCountryId = null;
      // if (
      //   !this.booking.originCountry &&
      //   this.$store.state.currentOrg.countryCode
      // ) {
      //   let find = this.countries.find(
      //     (x) => x.iso2 == this.$store.state.currentOrg.countryCode
      //   );
      //   if (find) {
      //     this.booking.originCountry = find;
      //     this.booking.originCountryId = find.id;
      //   }
      // }
      // this.booking.destinationCountry = null;
      // this.booking.destinationCountryId = null;
      let parties = [
        "buyer",
        "shipper",
        "consignee",
        "forwarder",
        "firstNotify",
        "secondNotify",
        "courierParty",
        // "onBehalfShipper",
        // "onBehalfConsignee",
        // "onBehalfForwarder",
        // "onBehalfBuyer",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = null;
        this.booking[parties[i] + "Id"] = null;
      }
      for (let i = 0; i < parties.length; i++) {
        if (organisation[parties[i]]) {
          this.booking[parties[i]] = organisation.relatedOrganisation;
          this.booking[parties[i] + "Id"] = organisation.relatedOrganisationId;
        }
      }
      this.getShipmentProfiles();
      this.customerSearchModal = false;
      this.loadingLinkedOrganisations = true;
      this.linkedOrganisations = await this.$API.getCustomerRelationships(
        organisation.relatedOrganisationId
      );
      this.loadingLinkedOrganisations = false;
    },
    setCountry(country) {
      if (this.countryType == "Origin") {
        this.booking.originCountry = country;
        this.booking.originCountryId = country.id;
      } else if (this.countryType == "Destination") {
        this.booking.destinationCountry = country;
        this.booking.destinationCountryId = country.id;
      }
      this.countryModal = false;
      this.searchCountries = null;
    },
    async setDealTerm(term) {
      this.booking.dealTermId = term.id;
      this.booking.dealTerm = term;
      this.booking.incoTerm = term.incoTerm;
      if (
        term.consigneeProfileShippingContracts &&
        term.consigneeProfileShippingContracts.length > 0
      ) {
        this.selectedCarriers = term.consigneeProfileShippingContracts
          .filter((x) => x.shippingLine)
          .map((x) => x.shippingLine.scacCode)
          .filter(Boolean);
      } else {
        this.shippingLines.filter((x) => x.scacCode).map((x) => x.scacCode);
      }
      this.shipmentProfileConfig = false;
      this.booking.bookingPaymentDetails = [];
      if(this.booking[term.contractOwner]){
        this.booking.contractParty = this.booking[term.contractOwner]
        this.booking.contractPartyId = this.booking[term.contractOwner + "Id"]
      }
      if (this.booking.dealTerm) {
        if (this.booking.dealTerm.freightCharge) {
          this.paymentItem = {
            chargeType: "OceanFreight",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.paymentTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.freightCharge),
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.originCharge) {
          this.paymentItem = {
            chargeType: "OriginTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.originTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.originCharge),
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.destinationCharge) {
          this.paymentItem = {
            chargeType: "DestinationTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.destinationTerm,
            paymentParty: this.formatParty(
              this.booking.dealTerm.destinationCharge
            ),
          };
          await this.savePaymentItem();
        }
        // if (term.consigneeProfileShippingContracts) {
        //     this.selectedCarriers = term.consigneeProfileShippingContracts.filter(x => x.shippingLine).map(x => x.shippingLine.scacCode).filter(Boolean)
        //     this.searchKey++
        // }
        // incoTerm.consigneeProfileShippingContracts
      }
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.containerItem[setFalse[i]] = false;
        }
        this.containerItem[haulier.flag] = true;
        this.containerItem.transportCoordinator = null;
        this.containerItem.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.containerItem[types[i]] = false;
        }
        this.containerItem.transportCoordinator = haulier;
        this.containerItem.transportCoordinatorId = haulier.id;
        // this.booking.preAdvise = true
      }
      this.haulierKey++;
      // this.haulierModal = false
    },
    setTransporter() {
      if (this.containerItem.transportCoordinator) {
        this.containerItem.transportCoordinatorId =
          this.containerItem.transportCoordinator.id;
      } else {
        this.containerItem.transportCoordinatorId = null;
      }
      if (
        this.containerItem.transportCoordinatorId ==
        this.$store.state.currentOrg.id &&
        this.booking.movementType == "EXPORT"
      ) {
        this.containerItem.preAdviseRequired = true;
      }
      this.preadviseKey++;
    },
    setIncoTerm(term) {
      this.booking.incoTerm = term.abbreviation;
      this.incoTermModal = false;
    },
    setLoadingPoint(point) {
      if (this.loadingPointItem.type == "emptyLoadingPoint") {
        this.containerItem.emptyPickup = point.name;
        this.containerItem.emptyPickupPoi = point;
        this.containerItem.emptyPickupPoiId = point.id;
      } else if (this.loadingPointItem.type == "firstLoadingPoint") {
        this.containerItem.firstLoadingPoint = point.name;
        this.containerItem.firstLoadingPointPoi = point;
        this.containerItem.firstLoadingPointPoiId = point.id;
      } else if (this.loadingPointItem.type == "secondLoadingPoint") {
        this.containerItem.secondLoadingPoint = point.name;
        this.containerItem.secondLoadingPointPoi = point;
        this.containerItem.secondLoadingPointPoiId = point.id;
      } else if (this.loadingPointItem.type == "dropOffPoint") {
        this.containerItem.dropOffPoint = point.name;
        this.containerItem.dropOffPointPoi = point;
        this.containerItem.dropOffPointPoiId = point.id;
      }
      this.poiSearch = null;
      this.loadingPointKey++;
      this.poiModal = false;
      this.loadingPointItem = {};
    },
    setParty(organisation) {
      if (!this.containerItemModal) {
        switch (this.partyType) {
          case "Shipper":
            this.booking.shipper = organisation;
            this.booking.shipperId = organisation.id;
            break;
          case "Consignee":
            this.booking.consignee = organisation;
            this.booking.consigneeId = organisation.id;
            break;
          case "Forwarder":
            this.booking.forwarder = organisation;
            this.booking.forwarderId = organisation.id;
            break;
          case "Buyer":
            this.booking.buyer = organisation;
            this.booking.buyerId = organisation.id;
            break;
          case "First Notify":
            this.booking.firstNotify = organisation;
            this.booking.firstNotifyId = organisation.id;
            break;
          case "Second Notify":
            this.booking.secondNotify = organisation;
            this.booking.secondNotifyId = organisation.id;
            break;
          case "Courier Party":
            this.booking.courierParty = organisation;
            this.booking.courierPartyId = organisation.id;
            break;
          case "Stock Provider":
            this.booking.stockProvider = organisation;
            this.booking.stockProviderId = organisation.id;
            break;
        }
      } else {
        switch (this.partyType) {
          case "Consignee":
            this.containerItem.consignee = organisation;
            this.containerItem.consigneeId = organisation.id;
            break;
          case "Buyer":
            this.containerItem.buyer = organisation;
            this.containerItem.buyerId = organisation.id;
            break;
        }
      }
      this.partyModal = false;
    },
    setPort(type, item) {
      if (type == "portOfLoad") {
        this.booking.portOfLoadValue = item.locode ?? item.code;
        this.booking.portOfLoadCity = item.name;
        if (!this.booking.originCountry) {
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.portOfLoadValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.originCountry = findCountry;
            this.booking.originCountryId = findCountry.id;
          }
        }
      } else if (type == "portOfDischarge") {
        this.booking.portOfDischargeValue = item.locode ?? item.code;
        this.booking.portOfDischargeCity = item.name;
        if (!this.booking.finalDestinationValue) {
          this.booking.finalDestinationValue = item.locode ?? item.code;
          this.booking.finalDestinationCity = item.name;
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.destinationCountry = findCountry;
            this.booking.destinationCountryId = findCountry.id;
          }
        }
      } else if (type == "finalDestination") {
        this.booking.finalDestinationValue = item.locode ?? item.code;
        this.booking.finalDestinationCity = item.name;
        let findCountry = this.countries.find(
          (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
        );
        if (findCountry) {
          this.booking.destinationCountry = findCountry;
          this.booking.destinationCountryId = findCountry.id;
        }
        if (!this.booking.portOfDischargeValue) {
          this.booking.portOfDischargeValue = item.locode ?? item.code;
          this.booking.portOfDischargeCity = item.name;
        }
      }
      this.portSearch = null;
      this.polModal = false;
      this.podModal = false;
      this.fdModal = false;
    },
    async setRegimeCode(regime) {
      this.booking.regimeCode = regime.code;
      this.booking.regime = regime;
      this.booking.regimeId = regime.id;
      this.containerItem.setPointTemp = regime.setPointTemp;
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId)
        if (ventSettings.length > 0) {
          this.ventSettings = [
          // "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter(x => ventSettings.map(y => y.ventCode).includes(x))
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ]
        }
      } else {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",

          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ]
      }
      // if (regime.regimeVents && regime.regimeVents.length > 0) {
      //   this.ventSettings = [
      //     "Closed",
      //     "15",
      //     "20",
      //     "25",
      //     "28",
      //     "30",
      //     "35",
      //     "40",
      //     "50",
      //     "Fully Open",
      //     "CA",
      //     "MA",
      //     "Auto",
      //   ].filter((x) => regime.regimeVents.map((x) => x.ventCode).includes(x));
      // } else {
      //   this.ventSettings = [
      //     "Closed",
      //     "15",
      //     "20",
      //     "25",
      //     "28",
      //     "30",
      //     "35",
      //     "40",
      //     "50",
      //     "Fully Open",
      //     "CA",
      //     "MA",
      //     "Auto",
      //   ];
      // }
      if (this.ventSettings.length == 1) {
        this.containerItem.vents = this.ventSettings[0];
      }
      if (!this.booking.vents || !this.ventSettings.includes(this.booking.vents)) {
        this.booking.vents = this.ventSettings[0]
      }
      this.regimeCodeModal = false;
    },
    async addContainerToBooking(container) {
      // console.log("selectedContainer", this.selectedContainer);
      console.log(container)
      if (container.regime) {
        this.booking.regime = container.regime
        this.booking.regimeId = container.regime.id
        this.booking.regimeCode = container.regime.code
      }
      if (this.profileDetail && this.profileDetail.transportCoordinator) {
        container.transportCoordinatorId = this.profileDetail.transportCoordinator.id
        container.transportCoordinator = this.profileDetail.transportCoordinator
        container.isPrivateHaulage = this.profileDetail.isPrivateHaulage
        container.isRailage = this.profileDetail.isRailage
        container.isCarrierHaulage = this.profileDetail.isCarrierHaulage
        if (this.profileDetail.preAdviseRequired) {
          container.preAdviseRequired = this.profileDetail.preAdviseRequired
        }
      }
      this.booking.bookingContainerItems.push(container);
      // if (this.selectedContainer) {
      // } else {
      //   this.booking.bookingContainerItems = [];
      // }
      this.containerDialog = false;
      this.$emit("containerAdded");
    },
    changeMovementType() {
      if (this.booking.movementType == "EXPORT") {
        this.booking.movementType = "IMPORT";
        this.booking.bookingContainerItems.forEach((x) => {
          x.preAdviseRequired = false;
        });
      } else {
        this.booking.movementType = "EXPORT";
      }
    },
    async setShipmentProfile(profile) {
      console.log(JSON.parse(JSON.stringify(profile)))
      this.settingShipmentProfile = true;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.booking.incoTerm = null;
      this.booking.portOfLoadValue = null;
      this.booking.portOfLoadCity = null;
      this.booking.portOfDischargeValue = null;
      this.booking.portOfDischargeCity = null;
      this.booking.finalDestinationValue = null;
      this.booking.finalDestinationCity = null;
      this.booking.originCountry = null;
      this.booking.originCountryId = null;
      if (
        !this.booking.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.booking.originCountry = find;
          this.booking.originCountryId = find.id;
        }
      }
      this.booking.destinationCountry = null;
      this.booking.destinationCountryId = null;
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.profileDetail = profileDetail;
      if (profileDetail.bookingContainerItems.length > 0) {
        // this.booking.bookingContainerItems = [];
        // this.booking.bookingContainerItems =
        //   profileDetail.bookingContainerItems;
        this.containerDialog = true;
        this.profileDetailKey++;
      }
      // await new Promise((resolve) => {
      //   this.$once("containerAdded", () => {
      //     resolve();
      //   });
      // });
      // console.log(
      //   "this.booking.bookingContainerItems",
      //   this.booking.bookingContainerItems
      // );
      this.booking.consigneeProfile = profile;
      this.booking.ucrNo = profile.ucrNo
      this.booking.consigneeProfileId = profile.id;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
        "onBehalfForwarder",
        "onBehalfBuyer",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = profile[parties[i]];
        this.booking[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.booking.type = profileDetail.type;
      this.booking.movementType = profileDetail.movementType;
      this.booking.serviceType = profileDetail.serviceType;
      this.booking.modeOfTransport = profileDetail.modeOfTransport
      this.booking.originCountry = profile.originCountry;
      this.booking.originCountryId = profile.originCountryId;

      // if (!this.booking.destinationCountry) {
      this.booking.destinationCountry = profile.destinationCountry;
      this.booking.destinationCountryId = profile.destinationCountryId;
      // }
      // this.booking.finalDestinationValue = profileDetail.finalDestinationValue
      // this.booking.finalDestinationCity = profileDetail.finalDestinationCity
      if (!this.booking.finalDestinationValue) {
        this.booking.finalDestinationValue =
          profileDetail.finalDestinationValue;
        this.booking.finalDestinationCity = profileDetail.finalDestinationCity;
      }
      this.booking.regimeCode = profileDetail.regimeCode ? profileDetail.regimeCode : this.booking.regimeCode;
      this.booking.regime = profileDetail.regime ? profileDetail.regime : this.booking.regime;
      this.booking.regimeId = profileDetail.regimeId ? profileDetail.regimeId : this.booking.regimeId;
      this.booking.caProduct = profileDetail.caProduct;
      this.booking.isPrivateHaulage = profileDetail.isPrivateHaulage;
      this.booking.isRailage = profileDetail.isRailage;
      this.booking.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.transportCoordinator) {
        this.booking.transportCoordinatorId =
          profileDetail.transportCoordinatorId;
        this.booking.transportCoordinator = profileDetail.transportCoordinator;
      }
      if (profileDetail.bookingContainerItems.length > 0) {
        if (!this.booking.bookingContainerItems) {
          this.booking.bookingContainerItems = [];
          this.booking.bookingContainerItems =
            profileDetail.bookingContainerItems;
        }

        this.booking.bookingContainerItems.forEach((x) => {
          delete x.id;
          delete x.consigneeProfileId;
          delete x.createdAt;
          delete x.updatedAt;
          x.bookingCargoItems.forEach((y) => {
            delete y.id;
            delete y.bookingContainerItemId;
            delete y.createdAt;
            delete y.updatedAt;
          });
          x.containerMilestones ? x.containerMilestones.forEach((y) => {
            delete y.id;
            delete y.bookingContainerItemId;
            delete y.date;
            delete y.createdAt;
            delete y.updatedAt;
          }) : [];
        });
        console.log("Container Items", this.booking.bookingContainerItems);
      }
      // if (!profile.consigneeProfileProducts) {
      //   profile.consigneeProfileProducts =
      //     await this.$API.getConsigneeProfileProducts(profile.id);
      // }
      console.log("All dest ports", profile.allDestinationPorts);
      if (!profile.allDestinationPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        console.log("Dest ports");
        if (findDest.length == 1) {
          this.setPort("portOfDischarge", {
            locode: findDest[0].code,
            name: findDest[0].name,
          });
          // this.booking.portOfDischargeValue = findDest[0].code;
          // this.booking.finalDestinationValue = findDest[0].code;
          // this.booking.portOfDischargeCity = findDest[0].name;
          // this.booking.finalDestinationCity = findDest[0].name;
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "origin"
        );
        if (findDest.length == 1) {
          this.setPort("portOfLoad", {
            locode: findDest[0].code,
            name: findDest[0].name,
          });

          // this.booking.portOfLoadValue = findDest[0].code;
          // this.booking.portOfLoadCity = findDest[0].name;
        }
      }
      if (
        profileDetail.consigneeProfileIncoTerms &&
        profileDetail.consigneeProfileIncoTerms.length == 1
      ) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      this.shipmentProfileDialog = false;
      this.contractKey++;
      this.allOriginPorts = await this.$API.getPortsByCountry(
        profile.originCountry.iso2
      );
      this.allDestinationPorts = await this.$API.getPortsByCountry(
        profile.destinationCountry.iso2
      );
      this.settingShipmentProfile = false;
    },
    removeMilestone(milestone) {
      this.containerItem.containerMilestones = this.containerItem.containerMilestones.filter(x => x.type != milestone)
      let filteredMilestones = this.availableMilestones.filter(x => x.type == this.booking.movementType)
      for (let data of filteredMilestones) {
        let find = this.containerItem.containerMilestones.find(x => x.type == data.value)
        // if(find){
        this.containerItem[data.value] = find ?? null
        // }
        console.log(data.value, this.containerItem[data.value])
      }

      this.milestoneKey++
    },
    setVessel(vessel) {
      this.booking.vessel = vessel;
      this.booking.vesselId = vessel.id;
      this.vesselModal = false;
      this.voyageNumberModal = true;
    },
    setSelectableParties() {
      let keys = []
      console.log('Movement Type', this.booking.movementType)
      if (this.booking.movementType == 'EXPORT') {
        keys = [
          "forwarder",
          "shipper",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }
      else {
        keys = [
          "forwarder",
          "consignee",
          "shipper",
          "buyer",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }

      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i] + "Id"]) {
          result.push(this.booking[keys[i]]);
        }
      }
      if (this.booking.customerId) {
        let findCustomer = result.find((x) => x.id == this.booking.customerId);
        if (!findCustomer) {
          result.unshift(this.booking.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    selectSchedule(schedule) {
      this.booking.carrier = schedule.carrier;
      this.booking.vessel = schedule.vessel;
      this.booking.vesselId = schedule.vesselId;
      this.booking.voyage = schedule.voyage;
      this.booking.sailingSchedule = schedule;
      this.booking.sailingScheduleId = schedule.id;
      this.booking.carrierScac = schedule.scac;
      this.booking.serviceName = schedule.serviceName;
      this.booking.eta = schedule.eta;
      this.booking.etd = schedule.etd;
      this.booking.atd = null;
      this.booking.ata = null;
      this.booking.shippingLine = schedule.shippingLine;
      this.booking.shippingLineId = schedule.shippingLineId;
      this.booking.carrierName = schedule.carrier;
      this.booking.mainCarriageVesselName = schedule.vesselDescription;
      this.booking.mainCarriageConveyanceNumber = schedule.voyage;
      this.booking.stackDate = schedule.stackDate;
      this.booking.stackDateId = schedule.stackDateId;
      this.booking.isTransshipment = !schedule.direct;
      this.booking.transportRouting = schedule.via;
      let terms = [];
      if (this.booking.consigneeProfile) {
        for (
          let i = 0;
          i < this.booking.consigneeProfile.consigneeProfileIncoTerms.length;
          i++
        ) {
          let term = this.booking.consigneeProfile.consigneeProfileIncoTerms[i];
          let result = term.consigneeProfileShippingContracts
            .filter((x) => x.shippingLine)
            .map((y) => ({
              id: term.id,
              incoTerm: term.incoTerm,
              shippingLine: y.shippingLine,
              scacCode: y.shippingLine.scacCode,
              contractNumber: y.contractNumber,
              contractOwner: y.contractOwner,
            }));
          terms = [...terms, ...result];
        }
      }
      this.suggestedDealTerms = terms.filter(
        (x) => x.scacCode == this.booking.carrierScac
      );
      if (this.suggestedDealTerms.length == 1) {
        this.booking.contractOwner = this.suggestedDealTerms[0].contractOwner;
        this.booking.contractNumber = this.suggestedDealTerms[0].contractNumber;
      }
      this.sailingScheduleModal = false;
      this.$emit('bookingChange')
    },
    
    setShippingLine(item) {
      this.booking.shippingLine = item;
      this.booking.shippingLineId = item.id;
      this.shippingLineModal = false;
    },
    updateHazardousStatus() {
      if (this.cargoItem.hazardous) {
        this.booking.isHazardous = true;
      } else {
        this.booking.isHazardous = this.booking.bookingContainerItems.some(
          (x) => x.bookingCargoItems.some((y) => y.hazardous == true)
        );
      }
    },
    removeRegime(){
      this.booking.regime = null
      this.booking.regimeCode = null
      this.booking.regimeId = null
      this.regimeKey++
    }
  },
};
</script>
